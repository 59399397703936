import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import Hero from "../../../components/hero/hero"
import FullCenter from "../../../components/text/fullCenterNoBtn"
import Gallery from "../../../components/gallery/gallery"

import HeroImg from "../../../images/worktops/silestone/1.jpg"
import Img from "../../../images/worktops/silestone/1.jpg"
import Img2 from "../../../images/worktops/silestone/2.jpg"
import Img3 from "../../../images/worktops/silestone/3.jpg"
import Img4 from "../../../images/worktops/silestone/4.jpg"
import Img5 from "../../../images/worktops/silestone/5.jpg"
import Img6 from "../../../images/worktops/silestone/6.jpg"
import Img7 from "../../../images/worktops/silestone/7.jpg"


const Silestone = () => (
    <Layout>
    <SEO title="Silestone" />

    <Hero Short ImgPath={HeroImg} TopTitle="If you can think it, We can design it" MainTitle="Silestone" SubTitle=""></Hero>

    <div className="container">
      <FullCenter Title="Silestone" Description="Silestone is an outstanding, market leading, innovative product made exclusively for you.<br></br>Made up of 94% natural quartz, its look is simply sublime.<br></br>Embeded in revolutionary anti-bacterial technology, stain resistant, and an unmatched 25 year guarantee, you need look no further for the worktop to compliment your dream kitchen."/>

    <div className="container flex">

      <Gallery ImgPath={Img}></Gallery>
      <Gallery ImgPath={Img2}></Gallery>
      <Gallery ImgPath={Img3}></Gallery>
      <Gallery ImgPath={Img4}></Gallery>
      <Gallery ImgPath={Img5}></Gallery>
      <Gallery ImgPath={Img6}></Gallery>
      <Gallery ImgPath={Img7}></Gallery>
    
    </div>
    
    
    </div>


  </Layout>
)

export default Silestone