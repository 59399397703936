import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Gallery from "../../components/gallery/gallery"
import FullCenter from "../../components/text/fullCenterBathrooms"
import Hero from "../../components/hero/hero"

import HeroImg from "../../images/bathrooms/axor/hero.jpg"
import Img from "../../images/bathrooms/axor/1.jpg"
import Img2 from "../../images/bathrooms/axor/2.jpg"
import Img3 from "../../images/bathrooms/axor/3.jpg"
import Img4 from "../../images/bathrooms/axor/4.jpg"
import Img5 from "../../images/bathrooms/axor/5.jpg"
import Img6 from "../../images/bathrooms/axor/6.jpg"
import Img7 from "../../images/bathrooms/axor/7.jpg"
import Img8 from "../../images/bathrooms/axor/8.jpg"

const Axor = () => (
  <Layout>
    <SEO title="AXOR" />

    <Hero Short ImgPath={HeroImg} TopTitle="" MainTitle="AXOR" SubTitle=""></Hero>

    <div className="container">
      <FullCenter Title="Designer mixers and showers from AXOR" Description="AXOR is a brand associated with luxury in bathrooms and kitchens. Avantgarde, one-of-a-kind products and collections are created to the highest aesthetic and technological standards. They all live up to the aspiration 'Form Follows Perfection'. The development process continues until nothing more can be added or removed. For more than 20 years now, trendsetting designer products, which include major bathroom design classics, have been created in collaboration with internationally renowned architects and designers.<br><br> AXOR is continually redefining aesthetics, water design and functionality. With award-winning mixers, showers and accessories. Providing incentives for bathroom design. Setting new benchmarks for individuality and exclusivity. In the project business. For private customers. For bathroom planners and interior designers. Form Follows Perfection. AXOR."/>

      <div className="flex">
        <Gallery ImgPath={Img}></Gallery>
        <Gallery ImgPath={Img2}></Gallery>
        <Gallery ImgPath={Img3}></Gallery>
        <Gallery ImgPath={Img4}></Gallery>
        <Gallery ImgPath={Img5}></Gallery>
        <Gallery ImgPath={Img6}></Gallery>
        <Gallery ImgPath={Img7}></Gallery>
        <Gallery ImgPath={Img8}></Gallery>
        </div>
    </div>

  </Layout>
)

export default Axor


