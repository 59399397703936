import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

const StyledUl = styled.ul`
  list-style:none;
  text-transform:uppercase;
  font-size:14px;
  display:block;
  position:relative;
  float:left;
  width:100%;
  margin:0;
  padding:0;
  ${media.greaterThan("medium")`
    display:inline-block;
    float:right;
    width:100%;
  `}
  ${media.greaterThan("large")`
    width:60%;
  `}
`
const StyledLi = styled.li`
    display:block;
    margin:15px 20px;
    text-align:center;
    font-size:11px;
    ${media.greaterThan("medium")`
        display:inline-block;
        float:right;
        margin:5px 20px;
    `}
`
const StyledLink = styled(Link)`
    color:white;
    text-decoration:none;
    &:hover{
        font-weight:700;
        text-decoration:underline;
    }
`
const FooterNav = () => (

    <StyledUl>
        <StyledLi><StyledLink to="/about-us/">About us</StyledLink></StyledLi>
        <StyledLi><StyledLink to="/contact-us/">Contact us</StyledLink></StyledLi>
        <StyledLi><StyledLink to="/terms-of-use/">Terms of use</StyledLink></StyledLi>
        <StyledLi><StyledLink to="/cookie-policy/">Cookie Policy</StyledLink></StyledLi>
        <StyledLi><StyledLink to="/privacy-policy/">Privacy Policy</StyledLink></StyledLi>
    </StyledUl>

)

export default FooterNav