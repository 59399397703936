import { Link } from "gatsby"
import styled from "styled-components"
import React from "react"

const Button = styled(Link)`
`
const ButtonTextStyle = styled.div`
    padding: 15px 25px;
    text-align: center;
    display:inline-block;
    background-color:#d5ac63;
    color:white;
    font-weight:600;
    text-transform:uppercase;
    font-size:12px;
    letter-spacing:1px;

    &:hover{
        background-color:#474747;
    }
`

const CtaButton = ({ pageTo, ButtonText}) => (


    <Button to={pageTo}>
        <ButtonTextStyle dangerouslySetInnerHTML={{ __html: ButtonText  }} />
    </Button>
)

export default CtaButton