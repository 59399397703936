import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Gallery from "../../components/gallery/gallery"
import FullCenter from "../../components/text/fullCenterBathrooms"
import Hero from "../../components/hero/hero"

import HeroImg from "../../images/bathrooms/kohler/hero.jpg"
import Img from "../../images/bathrooms/kohler/1.jpg"
import Img3 from "../../images/bathrooms/kohler/3.jpg"
import Img4 from "../../images/bathrooms/kohler/4.jpg"
import Img5 from "../../images/bathrooms/kohler/5.jpg"
import Img6 from "../../images/bathrooms/kohler/6.jpg"
import Img7 from "../../images/bathrooms/kohler/7.jpg"
import Img8 from "../../images/bathrooms/kohler/8.jpg"
import Img9 from "../../images/bathrooms/kohler/9.jpg"
import Img10 from "../../images/bathrooms/kohler/10.jpg"
import Img11 from "../../images/bathrooms/kohler/11.jpg"
import Img12 from "../../images/bathrooms/kohler/12.jpg"
import Img13 from "../../images/bathrooms/kohler/13.jpg"
import Img14 from "../../images/bathrooms/kohler/14.jpg"

const Kohler = () => (
  <Layout>
    <SEO title="Kohler" />

    <Hero Short ImgPath={HeroImg} TopTitle="The Bold Look of Kohler" MainTitle="Kohler" SubTitle=""></Hero>

    <div className="container">
      <FullCenter Title="The Bold Look of Kohler" Description="Since 1873, Kohler Co. has been improving the level of gracious living by providing exceptional products and services for your home and lifestyle.<br><br> We make it our business to help you create bathroom spaces that work for you. Whether it’s a showstopping cloakroom to welcome guests or a master bathroom designed to elevate your daily routines, Kohler has a ready solution.<br><br> We’re here to help you design a home that is an expression of you and the life you love."/>

      <div className="flex">
        <Gallery ImgPath={Img}></Gallery>
        <Gallery ImgPath={Img3}></Gallery>
        <Gallery ImgPath={Img4}></Gallery>
        <Gallery ImgPath={Img5}></Gallery>
        <Gallery ImgPath={Img6}></Gallery>
        <Gallery ImgPath={Img7}></Gallery>
        <Gallery ImgPath={Img8}></Gallery>
        <Gallery ImgPath={Img9}></Gallery>
        <Gallery ImgPath={Img10}></Gallery>
        <Gallery ImgPath={Img11}></Gallery>
        <Gallery ImgPath={Img12}></Gallery>
        <Gallery ImgPath={Img13}></Gallery>
        <Gallery ImgPath={Img14}></Gallery>
        </div>
    </div>

  </Layout>
)

export default Kohler
