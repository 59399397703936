import React from "react"

import "./layout.css"
import Header from "../components/header/header"
import Footer from "../components/footer/mainFooter"

const Layout = ({ children }) => {
  return (
    <>
      <Header></Header>
      <main>{children}</main>
      <Footer></Footer>
    </>
  )
}

export default Layout