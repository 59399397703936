import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero/hero"
import FullCenter from "../components/text/fullCenter"
import ProductsSelection from "../components/products/allProductsSelect"
import Cta from "../components/cta/whiteCta"

import Img from "../images/about/Wimbourne-main.jpg"
import ImgTwo from "../images/homepage/placeholder2.jpg"

import productKitchen from "../images/global/product-samples/kitchenSelect.jpg"
import productBathroom from "../images/homepage/products/placeholder-bathroom.jpg"
import productFlooring from "../images/ted-todd-flooring/Product-section-hero.jpg"
import productBedrooms from "../images/homepage/products/placeholder-bedroom.jpg"
import productPaint from "../images/homepage/products/placeholder-paint.jpg"


const Titleh5 = styled.h5`
  text-transform: uppercase;
  letter-spacing: 2px;
  margin:15px 0px;
`
const Titleh1 = styled.h1`
  letter-spacing: 2px;
  margin-bottom:20px;
  margin-top:20px;
`
const ProductsSection = styled.div`
  background-color:#d6d6d6;
  width:100%;
  height:auto;
  color:black;
  padding:40px 0px;
  overflow:hidden;
`
const ColOne = styled.div`
  width:100%;
  display:inline-block;
  margin:40px 0px;
  p{
    display:block;
    overflow:hidden;
    float:left;
  }
  ${media.greaterThan("medium") `
    width:45%;
  `}
`
const ColTwo = styled.div`
  display:none;
  background-image: url(${ImgTwo});
  background-size:cover;
  background-repeat:no-repeat;
  height:250px;
  float:right;
  margin:40px 0px;
  ${media.greaterThan("medium") `
    width:45%;
    display:inline-block;
  `}
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero ImgPath={Img} TopTitle="Welcome to" MainTitle="Acaelia Home Design" SubTitle="Macclesfield’s premium kitchen and bathroom showroom."></Hero>

    <div className="container">
      <FullCenter />
    </div>

    <ProductsSection>
      <div className="container">

        <ColOne>
          <Titleh5 white>What we offer</Titleh5>
          <Titleh1>Transform your home</Titleh1>
          <hr className="small"></hr>

          <p>Visit our showroom to look at the latest displays. We also offer over services along side kitchen and bathrooms such as flooring, bedroom furniture, wallpaper and paint.</p>
        </ColOne>

        <ColTwo></ColTwo>  
      </div>

      <div className="container flex">

        <ProductsSelection productsLarger ProductLink="/kitchens/" ImgPath={productKitchen} ProductTitle="Kitchens" DescriptionProduct="A kitchen is much more than just a place to cook. It has fast become the heart of the home and the hub of family life, it will witness many family memories."></ProductsSelection>
        <ProductsSelection productsLarger ProductLink="/bathrooms/" ImgPath={productBathroom} ProductTitle="Bathrooms" DescriptionProduct="From contemporary to traditional bathrooms. Creating the perfect bathroom is our priority through a vast range of choice"></ProductsSelection>
        <ProductsSelection ProductLink="/ted-todd-flooring/" ImgPath={productFlooring} ProductTitle="Ted Todd Flooring" DescriptionProduct="At Ted Todd, the pioneering development team are constantly pushing the boundaries of what a wood floor can be. The endless research, innovation and patience has now rendered a product which is that is simply breathtaking."></ProductsSelection>
        <ProductsSelection ProductLink="/laura-ashley-bedrooms/" ImgPath={productBedrooms} ProductTitle="Laura Ashley Bedrooms" DescriptionProduct="Laura Ashley Fitted Bedrooms include two expertly developed design options, Sherborne and Pelham. Both are available in eleven painted finishes, which can be accessorised with a wide range of handles."></ProductsSelection>
        <ProductsSelection ProductLink="/little-greene-paint/" ImgPath={productPaint} ProductTitle="Little Greene Paint" DescriptionProduct="With a vast variety of unique, traditional and contemporary colours and papers, you are sure to find your dream combinations here with our Industry Leading British Paint Manufacturer, Little Greene."></ProductsSelection>
      </div>
    </ProductsSection>
    
    <Cta></Cta>
  </Layout>
)

export default IndexPage


