import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Gallery from "../../components/gallery/gallery"
import FullCenter from "../../components/text/fullCenterBathrooms"
import Hero from "../../components/hero/hero"

import HeroImg from "../../images/bathrooms/burlington/hero.jpg"
import Img from "../../images/bathrooms/burlington/1.jpg"
import Img2 from "../../images/bathrooms/burlington/2.jpg"
import Img3 from "../../images/bathrooms/burlington/3.jpg"
import Img4 from "../../images/bathrooms/burlington/4.jpg"
import Img5 from "../../images/bathrooms/burlington/5.jpg"
import Img6 from "../../images/bathrooms/burlington/6.jpg"
import Img7 from "../../images/bathrooms/burlington/7.jpg"
import Img8 from "../../images/bathrooms/burlington/8.jpg"
import Img9 from "../../images/bathrooms/burlington/9.jpg"
import Img10 from "../../images/bathrooms/burlington/10.jpg"
import Img11 from "../../images/bathrooms/burlington/11.jpg"
import Img12 from "../../images/bathrooms/burlington/12.jpg"
import Img13 from "../../images/bathrooms/burlington/13.jpg"
import Img14 from "../../images/bathrooms/burlington/14.jpg"
import Img15 from "../../images/bathrooms/burlington/15.jpg"
import Img16 from "../../images/bathrooms/burlington/16.jpg"
import Img17 from "../../images/bathrooms/burlington/17.jpg"
import Img18 from "../../images/bathrooms/burlington/18.jpg"
import Img19 from "../../images/bathrooms/burlington/19.jpg"
import Img20 from "../../images/bathrooms/burlington/20.jpg"
import Img21 from "../../images/bathrooms/burlington/21.jpg"

const Burlington = () => (
  <Layout>
    <SEO title="Burlington" />

    <Hero Short ImgPath={HeroImg} TopTitle="" MainTitle="Burlington" SubTitle=""></Hero>

    <div className="container">
      <FullCenter Title="Classic, Traditional and Beautifully Elegant." Description="Burlington bathroom’s proudly presents their complete collection of beautiful, traditional bathrooms.<br></br>From the great era of classical British bathrooms, the ranges consist of Edwardian, Victorian, Classic and Contemporary.<br></br>The Burlington range is extensive, and allows you to create your very own traditional look, style, and past time.<br></br>With Burlington be safe in the knowledge that you are creating a classic, great British design portraying an era where Britain proudly led the way in bathroom design and performance."/>

      <div className="flex">
        <Gallery ImgPath={Img}></Gallery>
        <Gallery ImgPath={Img2}></Gallery>
        <Gallery ImgPath={Img3}></Gallery>
        <Gallery ImgPath={Img4}></Gallery>
        <Gallery ImgPath={Img5}></Gallery>
        <Gallery ImgPath={Img6}></Gallery>
        <Gallery ImgPath={Img7}></Gallery>
        <Gallery ImgPath={Img8}></Gallery>
        <Gallery ImgPath={Img9}></Gallery>
        <Gallery ImgPath={Img10}></Gallery>
        <Gallery ImgPath={Img11}></Gallery>
        <Gallery ImgPath={Img12}></Gallery>
        <Gallery ImgPath={Img13}></Gallery>
        <Gallery ImgPath={Img14}></Gallery>
        <Gallery ImgPath={Img15}></Gallery>
        <Gallery ImgPath={Img16}></Gallery>
        <Gallery ImgPath={Img17}></Gallery>
        <Gallery ImgPath={Img18}></Gallery>
        <Gallery ImgPath={Img19}></Gallery>
        <Gallery ImgPath={Img20}></Gallery>
        <Gallery ImgPath={Img21}></Gallery>
      </div>
    </div>

  </Layout>
)

export default Burlington


