import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import styled from "styled-components"

import FullCenter from "../../components/text/fullCenterNoBtn"
import ProductsSelection from "../../components/products/doubleProducts"
import Hero from "../../components/hero/hero"


import HeroImg from "../../images/appliances/heroImg.jpg"
import Smeg from "../../images/appliances/heroImg1.jpg"
import Siemens from "../../images/appliances/heroImg2.jpg"

const ProductsSection = styled.div`
  background-color:white;
  width:100%;
  height:auto;
  color:black;
  padding:40px 0px;
  overflow:hidden;
`
const Appliances = () => (
    <Layout>
    <SEO title="Appliances" />

    <Hero Short ImgPath={HeroImg} TopTitle="" MainTitle="Appliances" SubTitle=""></Hero>

    <div className="container">
      <FullCenter Title="Appliances" Description="Our extensive range of house hold appliances will provide you with a variety of options when choosing the appliances to compliment your new kitchen. From slow cookers, to steam ovens, we have it all.<br></br>Hosting the most innovative products on the market, you cannot fail to be impressed with our range of appliances."/>
    </div>
    
    <ProductsSection>
        <div className="container">
              <div className="flex">
                <ProductsSelection ProductsLarger ProductLink="kitchens/appliances/smeg" ImgPath={Smeg} ProductTitle="Smeg" DescriptionProduct="Smeg’s mission plan is simple, inspire and revolutionise home living."></ProductsSelection>

                <ProductsSelection ProductsLarger ProductLink="kitchens/appliances/siemens" ImgPath={Siemens} ProductTitle="Siemens" DescriptionProduct="Combining the quality of the German engineering industry, with excellent product performance and visionary ideas."></ProductsSelection>
              </div>
        </div>
    </ProductsSection>
    


  </Layout>
)

export default Appliances