import { Link } from "gatsby"
import styled from "styled-components"
import React from "react"
import media from "styled-media-query"

import Img from "../../images/global/logoReverted.png"

const FooterLogo = styled.div`
    width:100%;
    height:auto;
    display:block;
    float:none;
    margin:0 auto;
    text-align:center;
    ${media.greaterThan("medium")`
    display:inline-block;
    float:left;
    width:0%;
    `}
    ${media.greaterThan("large")`
    width:30%;
    `}
`
const LogoLink = styled(Link)`
    display:block;
    width:100%;
    height:auto;
    ${media.greaterThan("medium")`
    display:inline-block;
    float:left;
    `}
`
const PrimaryLogo = styled.div`
    width:200px;     
    height:50px;
    display:block;
    margin:0 auto;    
    background-image: url(${Img});
    background-size:contain;
    background-repeat:no-repeat;
    ${media.greaterThan("medium")`
        float:left;
        width:100px;        
    `}
    ${media.greaterThan("large")`
        width:200px;        
    `}
`

const LogoHeader = () => (

    <FooterLogo>
        <LogoLink to="/">
            <PrimaryLogo></PrimaryLogo>
        </LogoLink>
    </FooterLogo>

)

export default LogoHeader