import React from "react"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import Hero from "../../../components/hero/hero"
import FullCenter from "../../../components/text/fullCenterKitchens"
import Gallery from "../../../components/gallery/gallery"

import HeroImg from "../../../images/kitchen/modern/Hero.jpg"
import Img from "../../../images/kitchen/modern/kitchenModern1.jpg"
import Img2 from "../../../images/kitchen/modern/kitchenModern2.jpg"
import Img3 from "../../../images/kitchen/modern/kitchenModern3.jpg"
import Img4 from "../../../images/kitchen/modern/kitchenModern4.jpg"
import Img5 from "../../../images/kitchen/modern/kitchenModern5.jpg"
import Img6 from "../../../images/kitchen/modern/kitchenModern6.jpg"
import Img7 from "../../../images/kitchen/modern/kitchenModern7.jpg"
import Img8 from "../../../images/kitchen/modern/kitchenModern8.jpg"
import Img9 from "../../../images/kitchen/modern/kitchenModern9.jpg"
import Img10 from "../../../images/kitchen/modern/kitchenModern10.jpg"
import Img11 from "../../../images/kitchen/modern/kitchenModern11.jpg"
import Img12 from "../../../images/kitchen/modern/kitchenModern12.jpg"
import Img13 from "../../../images/kitchen/modern/kitchenModern13.jpg"
import Img14 from "../../../images/kitchen/modern/kitchenModern14.jpg"
import Img15 from "../../../images/kitchen/modern/kitchenModern15.jpg"
import Img16 from "../../../images/kitchen/modern/kitchenModern16.jpg"
import Img17 from "../../../images/kitchen/modern/kitchenModern17.jpg"
import Img18 from "../../../images/kitchen/modern/kitchenModern18.jpg"
import Img19 from "../../../images/kitchen/modern/kitchenModern19.jpg"
import Img20 from "../../../images/kitchen/modern/kitchenModern20.jpg"
import Img21 from "../../../images/kitchen/modern/kitchenModern21.jpg"
import Img22 from "../../../images/kitchen/modern/kitchenModern22.jpg"
import Img23 from "../../../images/kitchen/modern/kitchenModern23.jpg"
import Img24 from "../../../images/kitchen/modern/kitchenModern24.jpg"
import Img25 from "../../../images/kitchen/modern/kitchenModern25.jpg"
import Img26 from "../../../images/kitchen/modern/kitchenModern26.jpg"
import Img27 from "../../../images/kitchen/modern/kitchenModern27.jpg"
import Img28 from "../../../images/kitchen/modern/kitchenModern28.jpg"
import Img29 from "../../../images/kitchen/modern/kitchenModern29.jpg"
import Img30 from "../../../images/kitchen/modern/kitchenModern30.jpg"
import Img31 from "../../../images/kitchen/modern/kitchenModern31.jpg"
import Img32 from "../../../images/kitchen/modern/kitchenModern32.jpg"
import Img33 from "../../../images/kitchen/modern/kitchenModern33.jpg"
import Img34 from "../../../images/kitchen/modern/kitchenModern34.jpg"
import Img35 from "../../../images/kitchen/modern/kitchenModern35.jpg"
import Img36 from "../../../images/kitchen/modern/kitchenModern36.jpg"
import Img37 from "../../../images/kitchen/modern/kitchenModern37.jpg"
import Img38 from "../../../images/kitchen/modern/kitchenModern38.jpg"
import Img39 from "../../../images/kitchen/modern/kitchenModern39.jpg"
import Img40 from "../../../images/kitchen/modern/kitchenModern40.jpg"
import Img41 from "../../../images/kitchen/modern/kitchenModern41.jpg"
import Img42 from "../../../images/kitchen/modern/kitchenModern42.jpg"
import Img43 from "../../../images/kitchen/modern/kitchenModern43.jpg"
import Img44 from "../../../images/kitchen/modern/kitchenModern44.jpg"
import Img45 from "../../../images/kitchen/modern/kitchenModern45.jpg"
import Img46 from "../../../images/kitchen/modern/kitchenModern46.jpg"
import Img47 from "../../../images/kitchen/modern/kitchenModern47.jpg"
import Img48 from "../../../images/kitchen/modern/kitchenModern48.jpg"
import Img49 from "../../../images/kitchen/modern/kitchenModern49.jpg"
import Img50 from "../../../images/kitchen/modern/kitchenModern50.jpg"
import Img51 from "../../../images/kitchen/modern/kitchenModern51.jpg"
import Img52 from "../../../images/kitchen/modern/kitchenModern52.jpg"
import Img53 from "../../../images/kitchen/modern/kitchenModern53.jpg"
import Img54 from "../../../images/kitchen/modern/kitchenModern54.jpg"
import Img55 from "../../../images/kitchen/modern/kitchenModern55.jpg"
import Img56 from "../../../images/kitchen/modern/kitchenModern56.jpg"
import Img57 from "../../../images/kitchen/modern/kitchenModern57.jpg"
import Img58 from "../../../images/kitchen/modern/kitchenModern58.jpg"
import Img59 from "../../../images/kitchen/modern/kitchenModern59.jpg"
import Img60 from "../../../images/kitchen/modern/kitchenModern60.jpg"
import Img61 from "../../../images/kitchen/modern/kitchenModern61.jpg"
import Img62 from "../../../images/kitchen/modern/kitchenModern62.jpg"
import Img63 from "../../../images/kitchen/modern/kitchenModern63.jpg"
import Img64 from "../../../images/kitchen/modern/kitchenModern64.jpg"
import Img65 from "../../../images/kitchen/modern/kitchenModern65.jpg"
import Img66 from "../../../images/kitchen/modern/kitchenModern66.jpg"
import Img67 from "../../../images/kitchen/modern/kitchenModern67.jpg"
import Img68 from "../../../images/kitchen/modern/kitchenModern68.jpg"
import Img69 from "../../../images/kitchen/modern/kitchenModern69.jpg"
import Img70 from "../../../images/kitchen/modern/kitchenModern70.jpg"
import Img71 from "../../../images/kitchen/modern/kitchenModern71.jpg"
import Img72 from "../../../images/kitchen/modern/kitchenModern72.jpg"
import Img73 from "../../../images/kitchen/modern/kitchenModern73.jpg"

const ModernKitchen = () => (
  <Layout>
    <SEO title="Modern Kitchen Ranges" />

    <Hero Short ImgPath={HeroImg} TopTitle="" MainTitle="Modern Kitchens" SubTitle=""></Hero>

    <div className="container">
      <FullCenter Title="What is a modern kitchen?" Description="Nothing represents modern kitchens better than the sleek lines and design of the flat slab kitchen door. Choice and finish are ever more important for modern living. Masterclass now offers more colours and textured finishes than ever before, that can be combined with our unique design features to further enhance your dream kitchen."/>
    
      <div className="flex">
      <Gallery ImgPath={Img}></Gallery>
      <Gallery ImgPath={Img2}></Gallery>
      <Gallery ImgPath={Img3}></Gallery>
      <Gallery ImgPath={Img4}></Gallery>
      <Gallery ImgPath={Img5}></Gallery>
      <Gallery ImgPath={Img6}></Gallery>
      <Gallery ImgPath={Img7}></Gallery>
      <Gallery ImgPath={Img8}></Gallery>
      <Gallery ImgPath={Img9}></Gallery>
      <Gallery ImgPath={Img10}></Gallery>
      <Gallery ImgPath={Img11}></Gallery>
      <Gallery ImgPath={Img12}></Gallery>
      <Gallery ImgPath={Img13}></Gallery>
      <Gallery ImgPath={Img14}></Gallery>
      <Gallery ImgPath={Img15}></Gallery>
      <Gallery ImgPath={Img16}></Gallery>
      <Gallery ImgPath={Img17}></Gallery>
      <Gallery ImgPath={Img18}></Gallery>
      <Gallery ImgPath={Img19}></Gallery>
      <Gallery ImgPath={Img20}></Gallery>
      <Gallery ImgPath={Img21}></Gallery>
      <Gallery ImgPath={Img22}></Gallery>
      <Gallery ImgPath={Img23}></Gallery>
      <Gallery ImgPath={Img24}></Gallery>
      <Gallery ImgPath={Img25}></Gallery>
      <Gallery ImgPath={Img26}></Gallery>
      <Gallery ImgPath={Img27}></Gallery>
      <Gallery ImgPath={Img28}></Gallery>
      <Gallery ImgPath={Img29}></Gallery>
      <Gallery ImgPath={Img30}></Gallery>
      <Gallery ImgPath={Img31}></Gallery>
      <Gallery ImgPath={Img32}></Gallery>
      <Gallery ImgPath={Img33}></Gallery>
      <Gallery ImgPath={Img34}></Gallery>
      <Gallery ImgPath={Img35}></Gallery>
      <Gallery ImgPath={Img36}></Gallery>
      <Gallery ImgPath={Img37}></Gallery>
      <Gallery ImgPath={Img38}></Gallery>
      <Gallery ImgPath={Img39}></Gallery>
      <Gallery ImgPath={Img40}></Gallery>
      <Gallery ImgPath={Img41}></Gallery>
      <Gallery ImgPath={Img42}></Gallery>
      <Gallery ImgPath={Img43}></Gallery>
      <Gallery ImgPath={Img44}></Gallery>
      <Gallery ImgPath={Img45}></Gallery>
      <Gallery ImgPath={Img46}></Gallery>
      <Gallery ImgPath={Img47}></Gallery>
      <Gallery ImgPath={Img48}></Gallery>
      <Gallery ImgPath={Img49}></Gallery>
      <Gallery ImgPath={Img50}></Gallery>
      <Gallery ImgPath={Img51}></Gallery>
      <Gallery ImgPath={Img52}></Gallery>
      <Gallery ImgPath={Img53}></Gallery>
      <Gallery ImgPath={Img54}></Gallery>
      <Gallery ImgPath={Img55}></Gallery>
      <Gallery ImgPath={Img56}></Gallery>
      <Gallery ImgPath={Img57}></Gallery>
      <Gallery ImgPath={Img58}></Gallery>
      <Gallery ImgPath={Img59}></Gallery>
      <Gallery ImgPath={Img60}></Gallery>
      <Gallery ImgPath={Img61}></Gallery>
      <Gallery ImgPath={Img62}></Gallery>
      <Gallery ImgPath={Img63}></Gallery>
      <Gallery ImgPath={Img64}></Gallery>
      <Gallery ImgPath={Img65}></Gallery>
      <Gallery ImgPath={Img66}></Gallery>
      <Gallery ImgPath={Img67}></Gallery>
      <Gallery ImgPath={Img68}></Gallery>
      <Gallery ImgPath={Img69}></Gallery>
      <Gallery ImgPath={Img70}></Gallery>
      <Gallery ImgPath={Img71}></Gallery>
      <Gallery ImgPath={Img72}></Gallery>
      <Gallery ImgPath={Img73}></Gallery>
      </div>
    </div>
  </Layout>
)

export default ModernKitchen


