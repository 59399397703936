import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Img from "../images/contact/Larna-main.jpg"

const FooterTemplate = styled.div`
    margin-top:50px;
`
const ContactImg = styled.div`
    width:100%;
    float:left;
    height:30vh;
    margin-bottom:0px;
    background-image: url(${Img});
    background-size:cover;

    ${media.greaterThan("medium") `
        width:50%;
        height:100vh;
    `}
`
const ContactSection = styled.div`
    float:left;
    width:100%;
    margin:40px 0px;
    padding:0px;
    ${media.greaterThan("medium") `
        width:45%;
        float:right;
        height:100vh;
        padding:0px;
    `}
    ${media.greaterThan("large") `
        width:25%;
        float:right;
        height:100vh;
        padding:20px 100px 0px;
    `}
`
const Title = styled.div`
    font-weight:700;
`

const contactUs = () => (
  <Layout>
    <SEO title="Contact us" />
    
    <div class="container">
        <FooterTemplate>

            <ContactImg></ContactImg>

            <ContactSection>
                <h2>Get in touch</h2>
                
                <Title>Email Address</Title>
                <p><a href="mailto:enquiries@acaelia.com">enquiries@acaelia.com</a></p>

                <Title>Telephone</Title>
                <p><a href="tel:01625 464 955">01625 464 955</a></p>

                <Title>Location</Title>

                <p>Unit 4 Fence Avenue, Industrial Estate, <br></br>Macclesfield, Cheshire, SK10 1LT</p>


                <Title>Opening Times</Title>
                <p>Mon - Fri 9.00 AM - 5.00PM<br></br>
                Saturday 10.00AM - 2.00PM<br></br>
                Sunday – Closed</p>

            </ContactSection>

        </FooterTemplate>
        </div>
    </Layout>

)

export default contactUs
