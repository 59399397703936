import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Gallery from "../../components/gallery/gallery"
import FullCenter from "../../components/text/fullCenterBathrooms"
import Hero from "../../components/hero/hero"

import HeroImg from "../../images/bathrooms/villeroy-boch/hero.jpg"
import Img from "../../images/bathrooms/villeroy-boch/bathroom2.jpg"
import Img3 from "../../images/bathrooms/villeroy-boch/bathroom3.jpg"
import Img4 from "../../images/bathrooms/villeroy-boch/bathroom4.jpg"
import Img5 from "../../images/bathrooms/villeroy-boch/bathroom5.jpg"
import Img6 from "../../images/bathrooms/villeroy-boch/bathroom6.jpg"
import Img7 from "../../images/bathrooms/villeroy-boch/bathroom7.jpg"
import Img8 from "../../images/bathrooms/villeroy-boch/bathroom8.jpg"
import Img9 from "../../images/bathrooms/villeroy-boch/bathroom9.jpg"
import Img10 from "../../images/bathrooms/villeroy-boch/bathroom10.jpg"
import Img11 from "../../images/bathrooms/villeroy-boch/bathroom11.jpg"
import Img12 from "../../images/bathrooms/villeroy-boch/bathroom12.jpg"
import Img13 from "../../images/bathrooms/villeroy-boch/bathroom13.jpg"
import Img14 from "../../images/bathrooms/villeroy-boch/bathroom14.jpg"
import Img15 from "../../images/bathrooms/villeroy-boch/bathroom15.jpg"
import Img16 from "../../images/bathrooms/villeroy-boch/bathroom16.jpg"
import Img17 from "../../images/bathrooms/villeroy-boch/bathroom17.jpg"
import Img18 from "../../images/bathrooms/villeroy-boch/bathroom18.jpg"
import Img19 from "../../images/bathrooms/villeroy-boch/bathroom19.jpg"
import Img20 from "../../images/bathrooms/villeroy-boch/bathroom20.jpg"
import Img21 from "../../images/bathrooms/villeroy-boch/bathroom21.jpg"
import Img22 from "../../images/bathrooms/villeroy-boch/bathroom22.jpg"
import Img23 from "../../images/bathrooms/villeroy-boch/bathroom23.jpg"
import Img24 from "../../images/bathrooms/villeroy-boch/bathroom24.jpg"
import Img25 from "../../images/bathrooms/villeroy-boch/bathroom25.jpg"
import Img26 from "../../images/bathrooms/villeroy-boch/bathroom26.jpg"
import Img27 from "../../images/bathrooms/villeroy-boch/bathroom27.jpg"
import Img28 from "../../images/bathrooms/villeroy-boch/bathroom28.jpg"
import Img29 from "../../images/bathrooms/villeroy-boch/bathroom29.jpg"
import Img30 from "../../images/bathrooms/villeroy-boch/bathroom30.jpg"
import Img31 from "../../images/bathrooms/villeroy-boch/bathroom31.jpg"
import Img32 from "../../images/bathrooms/villeroy-boch/bathroom32.jpg"
import Img33 from "../../images/bathrooms/villeroy-boch/bathroom33.jpg"
import Img34 from "../../images/bathrooms/villeroy-boch/bathroom34.jpg"
import Img35 from "../../images/bathrooms/villeroy-boch/bathroom35.jpg"
import Img36 from "../../images/bathrooms/villeroy-boch/bathroom36.jpg"
import Img37 from "../../images/bathrooms/villeroy-boch/bathroom37.jpg"
import Img38 from "../../images/bathrooms/villeroy-boch/bathroom38.jpg"
import Img39 from "../../images/bathrooms/villeroy-boch/bathroom39.jpg"
import Img40 from "../../images/bathrooms/villeroy-boch/bathroom40.jpg"
import Img41 from "../../images/bathrooms/villeroy-boch/bathroom41.jpg"
import Img42 from "../../images/bathrooms/villeroy-boch/bathroom42.jpg"
import Img43 from "../../images/bathrooms/villeroy-boch/bathroom43.jpg"
import Img44 from "../../images/bathrooms/villeroy-boch/bathroom44.jpg"
import Img45 from "../../images/bathrooms/villeroy-boch/bathroom45.jpg"
import Img46 from "../../images/bathrooms/villeroy-boch/bathroom46.jpg"
import Img47 from "../../images/bathrooms/villeroy-boch/bathroom47.jpg"
import Img48 from "../../images/bathrooms/villeroy-boch/bathroom48.jpg"
import Img49 from "../../images/bathrooms/villeroy-boch/bathroom49.jpg"
import Img50 from "../../images/bathrooms/villeroy-boch/bathroom50.jpg"
import Img51 from "../../images/bathrooms/villeroy-boch/bathroom51.jpg"
import Img52 from "../../images/bathrooms/villeroy-boch/bathroom52.jpg"
import Img53 from "../../images/bathrooms/villeroy-boch/bathroom53.jpg"
import Img54 from "../../images/bathrooms/villeroy-boch/bathroom54.jpg"
import Img55 from "../../images/bathrooms/villeroy-boch/bathroom55.jpg"

const VandB = () => (
  <Layout>
    <SEO title="Villeroy And Boch" />

    <Hero Short ImgPath={HeroImg} TopTitle="" MainTitle="Villeroy And Boch" SubTitle=""></Hero>

    <div className="container">
    <FullCenter Title="Exceptional, Exclusive, Exquisite." Description="Villeroy &amp; Boch –  Industry Leading Bathroom Manufacturer’s <br></br>With roots stemming all the way back to 1748, at the heart of Europe, Villeroy &amp; Boch has fast become a world-renowned brand known for exceptional quality unrivalled in the market. <br></br>Villeroy and Boch, embedded within its history of a rich culture, innovation and style, has developed a quality and diversity within the market which simply cannot fail to impress. For hundreds of years, Villeroy &amp; Boch have been transforming functional bathrooms into stylish living spaces by using internationally recognised designers to rigorously follow trends and styles. Now, every Villeroy &amp; Boch bathroom has its own distinctive face. <br></br>There is however, one thing that all Villeroy &amp; Boch bathrooms have in common. First class quality. For centuries, the name Villeroy and Boch has simply been a byword for precise craftsmanship, state of art technology, and pure innovation. <br></br>Come and experience your very own Villeroy &amp; Boch oasis with us, and help us design your dream bathroom, exclusively for you."/>

    <div className="flex">
        <Gallery ImgPath={Img}></Gallery>
        <Gallery ImgPath={Img3}></Gallery>
        <Gallery ImgPath={Img4}></Gallery>
        <Gallery ImgPath={Img5}></Gallery>
        <Gallery ImgPath={Img6}></Gallery>
        <Gallery ImgPath={Img7}></Gallery>
        <Gallery ImgPath={Img8}></Gallery>
        <Gallery ImgPath={Img9}></Gallery>
        <Gallery ImgPath={Img10}></Gallery>
        <Gallery ImgPath={Img11}></Gallery>
        <Gallery ImgPath={Img12}></Gallery>
        <Gallery ImgPath={Img13}></Gallery>
        <Gallery ImgPath={Img14}></Gallery>
        <Gallery ImgPath={Img15}></Gallery>
        <Gallery ImgPath={Img16}></Gallery>
        <Gallery ImgPath={Img17}></Gallery>
        <Gallery ImgPath={Img18}></Gallery>
        <Gallery ImgPath={Img19}></Gallery>
        <Gallery ImgPath={Img20}></Gallery>
        <Gallery ImgPath={Img21}></Gallery>
        <Gallery ImgPath={Img22}></Gallery>
        <Gallery ImgPath={Img23}></Gallery>
        <Gallery ImgPath={Img24}></Gallery>
        <Gallery ImgPath={Img25}></Gallery>
        <Gallery ImgPath={Img26}></Gallery>
        <Gallery ImgPath={Img27}></Gallery>
        <Gallery ImgPath={Img28}></Gallery>
        <Gallery ImgPath={Img29}></Gallery>
        <Gallery ImgPath={Img30}></Gallery>
        <Gallery ImgPath={Img31}></Gallery>
        <Gallery ImgPath={Img32}></Gallery>
        <Gallery ImgPath={Img33}></Gallery>
        <Gallery ImgPath={Img34}></Gallery>
        <Gallery ImgPath={Img35}></Gallery>
        <Gallery ImgPath={Img36}></Gallery>
        <Gallery ImgPath={Img37}></Gallery>
        <Gallery ImgPath={Img38}></Gallery>
        <Gallery ImgPath={Img39}></Gallery>
        <Gallery ImgPath={Img40}></Gallery>
        <Gallery ImgPath={Img41}></Gallery>
        <Gallery ImgPath={Img42}></Gallery>
        <Gallery ImgPath={Img43}></Gallery>
        <Gallery ImgPath={Img44}></Gallery>
        <Gallery ImgPath={Img45}></Gallery>
        <Gallery ImgPath={Img46}></Gallery>
        <Gallery ImgPath={Img47}></Gallery>
        <Gallery ImgPath={Img48}></Gallery>
        <Gallery ImgPath={Img49}></Gallery>
        <Gallery ImgPath={Img50}></Gallery>
        <Gallery ImgPath={Img51}></Gallery>
        <Gallery ImgPath={Img52}></Gallery>
        <Gallery ImgPath={Img53}></Gallery>
        <Gallery ImgPath={Img54}></Gallery>
        <Gallery ImgPath={Img55}></Gallery>
      </div>
    </div>

  </Layout>
)

export default VandB


