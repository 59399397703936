import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

import Nav from "../../components/footer/mainFooterNav"
import Logo from "../../components/footer/footerLogo"


const Footer = styled.div`
    background-color:#474747;
    width:100%;
    height:100%;
    padding-top:30px;
    ${media.greaterThan("medium")`
        height:70px;
    `}

`
const SubFooter = styled.div`
    background-color:#383838;
    width:100%;
    height:50px;
    p{
        color:white;
        font-size:10px;
        padding-top:20px;
        margin:0;
        float:right;
    }
`

const MainFooter = () => (
    <> 
        <Footer>
            <div className="container">
                <Logo></Logo>
                <Nav></Nav>
            </div>
        </Footer>

        <SubFooter>
            <div className="container">
                <p>&copy; 2020 All rights reserved.</p>
            </div>
        </SubFooter>
    </>
)

export default MainFooter