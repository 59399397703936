import React from "react"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import Hero from "../../../components/hero/hero"
import Gallery from "../../../components/gallery/gallery"
import FullCenter from "../../../components/text/fullCenterKitchens"

import HeroImg from "../../../images/kitchen/classic/Hero.jpg"
import Img from "../../../images/kitchen/classic/kitchenClassic1.jpg"
import Img2 from "../../../images/kitchen/classic/kitchenClassic2.jpg"
import Img3 from "../../../images/kitchen/classic/kitchenClassic3.jpg"
import Img4 from "../../../images/kitchen/classic/kitchenClassic4.jpg"
import Img5 from "../../../images/kitchen/classic/kitchenClassic5.jpg"
import Img6 from "../../../images/kitchen/classic/kitchenClassic6.jpg"
import Img7 from "../../../images/kitchen/classic/kitchenClassic7.jpg"
import Img8 from "../../../images/kitchen/classic/kitchenClassic8.jpg"
import Img9 from "../../../images/kitchen/classic/kitchenClassic9.jpg"
import Img10 from "../../../images/kitchen/classic/kitchenClassic10.jpg"
import Img11 from "../../../images/kitchen/classic/kitchenClassic11.jpg"
import Img12 from "../../../images/kitchen/classic/kitchenClassic12.jpg"
import Img13 from "../../../images/kitchen/classic/kitchenClassic13.jpg"
import Img14 from "../../../images/kitchen/classic/kitchenClassic14.jpg"
import Img15 from "../../../images/kitchen/classic/kitchenClassic15.jpg"
import Img16 from "../../../images/kitchen/classic/kitchenClassic16.jpg"
import Img17 from "../../../images/kitchen/classic/kitchenClassic17.jpg"
import Img18 from "../../../images/kitchen/classic/kitchenClassic18.jpg"

const ClassicKitchen = () => (
  <Layout>
    <SEO title="Classic Kitchen Ranges" />

    <Hero Short ImgPath={HeroImg} TopTitle="" MainTitle="Classic Kitchens" SubTitle=""></Hero>

    <div className="container">
      <FullCenter Title="What is a classic kitchen?" Description="A simple design enhanced with traditional features epitomizes a classic kitchen.  This timeless style has stood the test of time and is one of the most flexible themes to create in a kitchen. Its bevelling on the recessed doors demonstrates an elegance and luxury that will give your new kitchen extravagant feel.<br><br> Traditionally, classic kitchens would usually display neutral colours such as creams, beiges and greys. But more and more people are braving the colour in their classic kitchen now. Mixing a bold colour with a more toned down colour can really add that wow-factor to your new kitchen."/>

    <div className="flex">
      <Gallery ImgPath={Img}></Gallery>
      <Gallery ImgPath={Img2}></Gallery>
      <Gallery ImgPath={Img3}></Gallery>
      <Gallery ImgPath={Img4}></Gallery>
      <Gallery ImgPath={Img5}></Gallery>
      <Gallery ImgPath={Img6}></Gallery>
      <Gallery ImgPath={Img7}></Gallery>
      <Gallery ImgPath={Img8}></Gallery>
      <Gallery ImgPath={Img9}></Gallery>
      <Gallery ImgPath={Img10}></Gallery>
      <Gallery ImgPath={Img11}></Gallery>
      <Gallery ImgPath={Img12}></Gallery>
      <Gallery ImgPath={Img13}></Gallery>
      <Gallery ImgPath={Img14}></Gallery>
      <Gallery ImgPath={Img15}></Gallery>
      <Gallery ImgPath={Img16}></Gallery>
      <Gallery ImgPath={Img17}></Gallery>
      <Gallery ImgPath={Img18}></Gallery>
    </div>


    </div>  

    </Layout>
)

export default ClassicKitchen


