import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero/hero"
import ProductsSelection from "../components/products/allProductsSelect"
import FullCenter from "../components/text/fullCenterNoBtn"
import Cta from "../components/cta/whiteCta"
import Reviews from "../components/testimonals/reviews"
import Gallery from "../components/gallery/gallery"

import Img from "../images/kitchen/shaker/Hero.jpg"
import productKitchen from "../images/global/product-samples/kitchenSelect.jpg"
import productBathroom from "../images/homepage/products/placeholder-bathroom.jpg"
import productFlooring from "../images/ted-todd-flooring/Product-section-hero.jpg"
import productBedrooms from "../images/homepage/products/placeholder-bedroom.jpg"
import productPaint from "../images/homepage/products/placeholder-paint.jpg"

import Reallife from "../images/real-life/1.jpg"
import Reallife2 from "../images/real-life/2.jpg"
import Reallife3 from "../images/real-life/3.jpg"
import Reallife4 from "../images/real-life/4.jpg"
import Reallife5 from "../images/real-life/5.jpg"
import Reallife6 from "../images/real-life/6.jpg"
import Reallife7 from "../images/real-life/7.jpg"
import Reallife8 from "../images/real-life/8.jpg"
import Reallife9 from "../images/real-life/9.jpg"
import Reallife10 from "../images/real-life/10.jpg"
import Reallife11 from "../images/real-life/11.jpg"
import Reallife12 from "../images/real-life/12.jpg"
import Reallife13 from "../images/real-life/13.jpg"
import Reallife14 from "../images/real-life/14.jpg"

const Titleh5 = styled.h5`
  text-transform: uppercase;
  letter-spacing: 2px;
  margin:15px 0px;
`
const Titleh1 = styled.h1`
  letter-spacing: 2px;
  margin-bottom:20px;
  margin-top:20px;
`
const ProductsSection = styled.div`
  background-color:#d6d6d6;
  width:100%;
  height:auto;
  color:black;
  padding:40px 0px;
  overflow:hidden;
  margin-top:80px;
`
const ColOne = styled.div`
  width:100%;
  display:inline-block;
  margin:40px 0px;
  p{
    display:block;
    overflow:hidden;
    float:left;
  }
  ${media.greaterThan("medium") `
    width:45%;
  `}
`
const ColTwo = styled.div`
  display:none;
  background-image: url(${Img});
  background-size:cover;
  background-repeat:no-repeat;
  background-position:bottom;
  height:250px;
  float:right;
  margin:40px 0px;
  ${media.greaterThan("medium") `
    width:45%;
    display:inline-block;
  `}
`

const Testimonals = () => (
  <Layout>
    <SEO title="Testimonals" />

    <Hero Short ImgPath={Img} TopTitle="" MainTitle="What our customers say" SubTitle="Take a look at some of our recent work"></Hero>

    <div className="container">
        <FullCenter Title="Testimonals" Description="What our customers say about our company" />
    </div>

    <div className="container flex">
        <Reviews QuoteDescription="My husband and I recently renovated two cottages and a leisure suite. We needed to install three bathrooms, two kitchens and an attractive cloak room with WC. As we weren’t sure if the cottages would ever be used by members of our family, holiday lets or long-term rentals we wanted long lasting, quality and attractive kitchens and bathrooms to cover all these uses and this is what we got from Acaelia. Shaun and Sally were always helpful, and the beautiful showroom really helped you visualize what it would look like. The price was competitive too as having shopped around for quotes we believed we purchased good quality products at a fair price. We wouldn’t hesitate to recommend Acaelia" Author="Mr and Mrs Horne, Pott Shrigley"></Reviews>
        
        <Reviews QuoteDescription="Thank you so much for your help and consideration in designing and supplying our new kitchen, changing room and bathrooms etc.<br><br>We are especially pleased with the backup and professionalism you have shown to us over the past few months. All the quality products are in place now and we are looking forward to moving into our new home in the near future.<br><br>We would most certainly recommend Acaelia to our family and friends in the future and indeed have already done so.<br><br>Thank you once again for your support." Author="Mr and Mrs Goodwin, Mottram St Andrew"></Reviews>

        <Reviews QuoteDescription="Acaelia Home Design have been fantastic from start to finish in the design and refurbishment of our kitchen. <br><br>Sally accommodated our requests and suggested some creative solutions on how to redesign the space; making it both functional and stylish. Shaun managed the project professionally, coordinating the various tradesmen. It was reassuring to have one point of contact and he was very accessible throughout the whole process. The tradesmen were friendly and efficient, and the project was finished on schedule and to a high standard. <br><br>We are delighted with the final result and would not hesitate to recommend Acaelia Home Design and NSJ." Author="Mr &amp; Mrs Seaman, Alderley Edge"></Reviews>

        <Reviews QuoteDescription="Having decided it was time to renovate our bathroom and change it into a shower/wet room we did research on line and visited some showrooms to explore options.  Acaelia Home Design’s well laid out showroom was very attractive, and Shaun was very helpful without being pushy, we soon decided this was the company we wanted to work with. The help and advice we received on the options and Shaun’s knowledge of the products gave us complete confidence in the ability of Acaelia Home Design to give us the outcome we were looking for. <br><br>Everything was arranged by the company – the removal of tiles, retiling, plumbing, installation of the new fitments, electrical work and even replacement and painting of a new door.  The workmen were all pleasure to have in the house and everything was left tidy at the end of each day.  We were very pleased with the standard of work and the professionalism of the company throughout the project.  We had confidence Acaelia wanted to make sure we would be completely satisfied and would work to ensure that all was in order.  The shower room is now complete to a very high finish and we would definitely recommend this company" Author="J. Burgess, Prestbury"></Reviews>

        <Reviews QuoteDescription="I have recently had a new wet room installed. Initially I was looking in several bathroom outlets and stores in the Cheshire area however I was struggling to 'bring everything together' as it were in terms of design and styling. I then approached Acaelia Home Design. From the very outset, they were extremely helpful and informative. I felt confident that anything and everything I was looking for could be sourced. <br><br>They also helped me enormously with designing the wet room itself, coming around to take photos and uploading them to a 3D design software programme so that I could see how my wet room was going to look. This was invaluable. <br><br>Every step of the way I was looked after. They really went the extra mile and so I cannot recommend them enough. Both Shaun and Sally from Acaelia were fantastic. If you’re looking for a highly professional, efficient home design company then Acaelia is the way to go. First class!" Author="Mr Clarke, Macclesfield"></Reviews>

        <Reviews QuoteDescription="Our new shower room and bedroom project has just been competed by the wonderful team at Acaelia. Manager, Shaun Chapman, guided us through each element of the design and product selection process, ensuring that it was was stressless and reassuring. Once the build commenced I was immediately impressed by every team member's commitment to the project and incredible attention to detail. The finished project has exceed our expectations and gives us considerable pleasure, every day I spot a new detail to admire - I am sure that I will continue to do so for many more weeks and months. I wouldn't hesitate to recommend Acaelia to any friends or family needing a reliable, professional and supportive project management team whose focus is entirely on delivering for the customer.  Many thanks!" Author="Anna &amp; Mrs Tobias, Poynton"></Reviews>

        <Reviews QuoteDescription="Acaelia Bathrooms recently supplied and fitted two bathrooms for us. Throughout the whole process we received outstanding service. Several innovative designs were prepared, and we had the opportunity to spend time reviewing these ahead of making our choices. The installation was completed on time and on budget by friendly staff. We were treated like special customers throughout; the quality of work was first class and regular visits by Shaun ensured we could ask questions and feel engaged in the whole process. We would recommend Acaelia with no hesitation" Author="M. and G. Shaughnessy, Tytherington"></Reviews>

        <Reviews QuoteDescription="Simon and I would like to thank you very much indeed for your excellent design service for our kitchen and bathrooms. We really appreciate all your time, attention and site visit. It really has been a pleasure to work with you and the designs you produced fitted our requirements perfectly. <br><br>You listened and took on board everything we requested and incorporated that along with some great ideas and suggestions from yourselves. Nothing was too much trouble, you were very patient, professional and extremely pleasant to work with. <br><br>We wouldn’t hesitate to recommend you to anyone" Author="Mr and Mrs Fletcher, Macclesfield"></Reviews>

        <Reviews QuoteDescription="Every step of the way we felt that we were in the hands of professionals. We now have a wonderful home and business to enjoy for many years to come" Author="Mr D. White, Baslow"></Reviews>

        <Reviews QuoteDescription="Their attention to detail, understanding and professionalism is more than we could have ever hoped for" Author="Mrs Emily W, Baslow"></Reviews>

    </div>

    <div className="container">
        <FullCenter Title="Our Real Life Gallery" Description="We love seeing how our customers have made a Acaelia Home Design part of their lives - take a look at the beautiful work our customer have collaborated on with us, and get some inspiration for your very own home transformation." />

    </div>

    <div className="container flex">
      <Gallery ImgPath={Reallife}></Gallery>
      <Gallery ImgPath={Reallife2}></Gallery>
      <Gallery ImgPath={Reallife3}></Gallery>
      <Gallery ImgPath={Reallife4}></Gallery>
      <Gallery ImgPath={Reallife5}></Gallery>
      <Gallery ImgPath={Reallife6}></Gallery>
      <Gallery ImgPath={Reallife7}></Gallery>
      <Gallery ImgPath={Reallife8}></Gallery>
      <Gallery ImgPath={Reallife9}></Gallery>
      <Gallery ImgPath={Reallife10}></Gallery>
      <Gallery ImgPath={Reallife11}></Gallery>
      <Gallery ImgPath={Reallife12}></Gallery>
      <Gallery ImgPath={Reallife13}></Gallery>
      <Gallery ImgPath={Reallife14}></Gallery>
    </div> 

    <ProductsSection>
      <div className="container">

        <ColOne>
          <Titleh5 white>What we offer</Titleh5>
          <Titleh1>Transform your home</Titleh1>
          <hr className="small"></hr>

          <p>Visit our showroom to look at the latest displays. We also offer over services along side kitchen and bathrooms such as flooring, bedroom furniture and wallpaper and paint.</p>
        </ColOne>

        <ColTwo></ColTwo>  
      </div>

      <div className="container flex">

        <ProductsSelection productsLarger ProductLink="/kitchens/" ImgPath={productKitchen} ProductTitle="Kitchens" DescriptionProduct="A kitchen is much more than just a place to cook. It has fast become the heart of the home and the hub of family life, it will witness many family memories."></ProductsSelection>
        <ProductsSelection productsLarger ProductLink="/bathrooms/" ImgPath={productBathroom} ProductTitle="Bathrooms" DescriptionProduct="From contemporary to traditional bathrooms. Creating the perfect bathroom is our priority through a vast range of choice"></ProductsSelection>
        <ProductsSelection ProductLink="/ted-todd-flooring/" ImgPath={productFlooring} ProductTitle="Ted Todd Flooring" DescriptionProduct="At Ted Todd, the pioneering development team are constantly pushing the boundaries of what a wood floor can be. The endless research, innovation and patience has now rendered a product which is that is simply breathtaking."></ProductsSelection>
        <ProductsSelection ProductLink="/laura-ashley-bedrooms/" ImgPath={productBedrooms} ProductTitle="Laura Ashley Bedrooms" DescriptionProduct="Laura Ashley Fitted Bedrooms include two expertly developed design options, Sherborne and Pelham. Both are available in eleven painted finishes, which can be accessorised with a wide range of handles."></ProductsSelection>
        <ProductsSelection ProductLink="/little-greene-paint/" ImgPath={productPaint} ProductTitle="Little Greene Paint" DescriptionProduct="With a vast variety of unique, traditional and contemporary colours and papers, you are sure to find your dream combinations here with our Industry Leading British Paint Manufacturer, Little Greene."></ProductsSelection>
      </div>
    </ProductsSection>
    <Cta></Cta>



  </Layout>
)

export default Testimonals


