import React from "react"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import FullCenter from "../../../components/text/fullCenterNoBtn"
import Gallery from "../../../components/gallery/gallery"
import Hero from "../../../components/hero/hero"

import HeroImg from "../../../images/appliances/heroImg1.jpg"
import Img from "../../../images/appliances/smeg/1.jpg"
import Img2 from "../../../images/appliances/smeg/2.jpg"
import Img3 from "../../../images/appliances/smeg/3.jpg"
import Img4 from "../../../images/appliances/smeg/4.jpg"
import Img5 from "../../../images/appliances/smeg/5.jpg"
import Img6 from "../../../images/appliances/smeg/6.jpg"
import Img7 from "../../../images/appliances/smeg/7.jpg"
import Img8 from "../../../images/appliances/smeg/8.jpg"
import Img9 from "../../../images/appliances/smeg/9.jpg"
import Img10 from "../../../images/appliances/smeg/10.jpg"
import Img11 from "../../../images/appliances/smeg/11.jpg"
import Img12 from "../../../images/appliances/smeg/12.jpg"
import Img13 from "../../../images/appliances/smeg/13.jpg"
import Img14 from "../../../images/appliances/smeg/14.jpg"
import Img15 from "../../../images/appliances/smeg/15.jpg"
import Img16 from "../../../images/appliances/smeg/16.jpg"
import Img17 from "../../../images/appliances/smeg/17.jpg"
import Img18 from "../../../images/appliances/smeg/18.jpg"


const Smeg = () => (
    <Layout>
    <SEO title="Smeg" />

    <Hero Short ImgPath={HeroImg} TopTitle="Italia’s Finest" MainTitle="SMEG" SubTitle=""></Hero>

    <div className="container">
      <FullCenter Title="SMEG" Description="Founded in the peaceful town near Reggio Emilia, Northern Italy, SMEG has grown to be one of the leading home appliance manufactures in the world.<br></br>The culture of innovation and product development is deeply embedded deep into SMEGS history, continuing to break through the boundary’s not previously thought possible.<br></br>SMEGS mission plan is simple, inspire and revolutionise home living. With such dedication to product development and market research, who knows what fantastic innovation will be next."/>

    <div className="container flex">

      <Gallery ImgPath={Img}></Gallery>
      <Gallery ImgPath={Img2}></Gallery>
      <Gallery ImgPath={Img3}></Gallery>
      <Gallery ImgPath={Img4}></Gallery>
      <Gallery ImgPath={Img5}></Gallery>
      <Gallery ImgPath={Img6}></Gallery>
      <Gallery ImgPath={Img7}></Gallery>
      <Gallery ImgPath={Img8}></Gallery>
      <Gallery ImgPath={Img9}></Gallery>
      <Gallery ImgPath={Img10}></Gallery>
      <Gallery ImgPath={Img11}></Gallery>
      <Gallery ImgPath={Img12}></Gallery>
      <Gallery ImgPath={Img13}></Gallery>
      <Gallery ImgPath={Img14}></Gallery>
      <Gallery ImgPath={Img15}></Gallery>
      <Gallery ImgPath={Img16}></Gallery>
      <Gallery ImgPath={Img17}></Gallery>
      <Gallery ImgPath={Img18}></Gallery>
    
    </div>
    
    
    </div>


  </Layout>
)

export default Smeg