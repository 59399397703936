import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import Hero from "../../../components/hero/hero"
import FullCenter from "../../../components/text/fullCenterNoBtn"
import Gallery from "../../../components/gallery/gallery"

import HeroImg from "../../../images/worktops/dekton/heroImg.jpg"
import Img from "../../../images/worktops/dekton/1.jpg"
import Img2 from "../../../images/worktops/dekton/2.jpg"
import Img3 from "../../../images/worktops/dekton/3.jpg"
import Img4 from "../../../images/worktops/dekton/4.jpg"
import Img5 from "../../../images/worktops/dekton/5.jpg"
import Img6 from "../../../images/worktops/dekton/6.jpg"

const Dekton = () => (
    <Layout>
    <SEO title="Dekton" />

    <Hero Short ImgPath={HeroImg} TopTitle="If you can think it, We can design it" MainTitle="Dekton" SubTitle=""></Hero>

    <div className="container">
      <FullCenter Title="Dekton" Description="Dekton worktop is epitomised by innovation at the highest level. A sophisticated mixture of more than 20 minerals extracted from nature and the results have concluded a product which in envied by all.<br></br>Scratch resistant, stain resistant UV resistant, with a structural density greater than granite! Dekton provides the perfect option to an ultra-contemporary feel to your kitchen."/>

    <div className="container flex">

      <Gallery ImgPath={Img}></Gallery>
      <Gallery ImgPath={Img2}></Gallery>
      <Gallery ImgPath={Img3}></Gallery>
      <Gallery ImgPath={Img4}></Gallery>
      <Gallery ImgPath={Img5}></Gallery>
      <Gallery ImgPath={Img6}></Gallery>
    
    </div>
    
    
    </div>


  </Layout>
)

export default Dekton