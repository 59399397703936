import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import media from "styled-media-query"

const ProductBox = styled(Link)`
    display:block;
    background-color:white;
    float:left;
    width:100%;
    height:auto;
    margin:20px 0px;
    text-decoration: none;

    &:hover{
        box-shadow: 3px 3px 3px 3px #bfbfbf;
    }
    ${media.greaterThan("medium") `
        width:31%;
        height:430px;
        margin:20px 7px;
    `}
    ${media.greaterThan("large") `
        width:32%;
        height:auto;
    `}
    ${media.greaterThan("huge") `
        width:32%;
        height:350px;
    `}
`
const Img = styled.div`
    background-color:#ccc;
    background-image:url(${props => props.Img});
    background-size:cover;
    background-repeat:no-repeat;
    width:100%;
    height:225px;
`
const Title = styled.h3`
    color:black;
    padding:0px 10px;
    font-weight:bold;
    margin-bottom:0;
`

const Description = styled.p`
    color:black;
    padding:0px 10px;
    font-size:12px;
`

const AllProducts = ({ProductLink, ProductTitle, DescriptionProduct, ImgPath}) => (
    <ProductBox to={ProductLink}>
        <Img Img={ImgPath} />
        <Title dangerouslySetInnerHTML={{ __html: ProductTitle }} />
        <Description dangerouslySetInnerHTML={{ __html: DescriptionProduct }} />
    </ProductBox>
)

export default AllProducts


