import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FullCenter from "../components/text/fullCenterNoBtn"
import ProductsSelection from "../components/products/allProductsSelect"
import Cta from "../components/cta/whiteCta"
import Hero from "../components/hero/hero"

import HeroImg from "../images/bathrooms/hero.jpg"

import ProductVilleroy from "../images/bathrooms/villeroy-boch/hero.jpg"
import productBurlington from "../images/bathrooms/burlington/hero.jpg"
import productBathroomFurntiture from "../images/bathrooms/bathroom-furniture-co/hero.jpg"
import productHansgrohe from "../images/bathrooms/hansgrohe/hero.jpg"
import productKeuco from "../images/bathrooms/keuco/hero.jpg"
import productMatki from "../images/bathrooms/matki/hero.jpg"
import productVitra from "../images/bathrooms/vitra/hero.jpg"
import productAxor from "../images/bathrooms/axor/hero.jpg"
import productAshton from "../images/bathrooms/ashton/hero.jpg"
import productVic from "../images/bathrooms/victoria/hero.jpg"
import productPerrin from "../images/bathrooms/perrin/hero.jpg"
import productKohler from "../images/bathrooms/kohler/hero.jpg"

const ProductsSection = styled.div`
  background-color:#d6d6d6;
  width:100%;
  height:auto;
  color:black;
  padding:40px 0px;
  overflow:hidden;
`

const Kitckens = () => (
  <Layout>
    <SEO title="About us" />

    <Hero ImgPath={HeroImg} TopTitle="A Relaxing Oasis Designed Exclusively For You" MainTitle="Bathroom collection" SubTitle=""></Hero>

    <div className="container">
      <FullCenter Title="Macclesfield’s Premium Bathroom Showroom" Description="Our luxury Macclesfield Bathroom showroom hosts over 30 beautiful bathroom displays. <br></br>We understand that choosing a new bathroom suite is not something that you intend to do regularly. That is why we only offer products that are designed to be, long lasting, reliable, and of course beautiful in design. Whether you choose from our contemporary Villeroy &amp; Boch range, to our classic Perrin and Rowe collection, you are sure to be impressed with the quality of all of our products. <br></br>Come and see our outstanding bathroom collection at our Macclesfield showroom and see how we can assist on your next project." ButtonText="Masterclass kitchen ranges" />
    </div>

    <ProductsSection>
        <div className="container">
            <h3>View all our bathroom ranges here:</h3>
                <div className="flex">
                  <ProductsSelection ProductsLarger ProductLink="bathrooms/villeroy-and-boch" ImgPath={ProductVilleroy} ProductTitle="Villeroy and Boch" DescriptionProduct="Timeless elegance, innovative design and outstanding quality: that is what the brand Villeroy &amp; Boch has stood for since 1748. Designers and product managers develop collections and concepts based on this tradition that shape living environments."></ProductsSelection>

                  <ProductsSelection ProductLink="bathrooms/kohler" ImgPath={productKohler} ProductTitle="Kohler" DescriptionProduct="Since 1873, Kohler Co. has been improving the level of gracious living by providing exceptional products and services for your home and lifestyle."></ProductsSelection>

                  <ProductsSelection ProductLink="bathrooms/perrin-and-rowe" ImgPath={productPerrin} ProductTitle="Perrin and Rowe" DescriptionProduct="The strength of the Perrin &amp; Rowe brand is built upon a clear understanding of the requirements in the luxury sector: product reliability, durability, quality and real value for money."></ProductsSelection>

                  <ProductsSelection ProductLink="bathrooms/hansgrohe" ImgPath={productHansgrohe} ProductTitle="hansgrohe" DescriptionProduct="hansgrohe, you can not only rely on a beautiful product to compliment your bathroom, but rest assured knowing that you have purchased a reliable, sturdy product designed for a long service life."></ProductsSelection>

                  <ProductsSelection ProductLink="bathrooms/axor" ImgPath={productAxor} ProductTitle="AXOR" DescriptionProduct="AXOR is a brand associated with luxury in bathrooms and kitchens. Avantgarde, one-of-a-kind products and collections are created to the highest aesthetic and technological standards."></ProductsSelection>

                  <ProductsSelection ProductLink="bathrooms/victoria+albert" ImgPath={productVic} ProductTitle="Victoria + Albert" DescriptionProduct="As the ultimate statement in quality and style, our model line-up is packed with inspirational free standing designs made from 100% QUARRYCAST<sup>&trade;</sup>, a blend of Volcanic Limestone<sup>&trade;</sup> and high performance resins."></ProductsSelection>       

                  <ProductsSelection ProductsLarger ProductLink="bathrooms/burlington" ImgPath={productBurlington} ProductTitle="Burlington" DescriptionProduct="Burlington bathroom’s proudly presents their complete collection of beautiful, traditional bathrooms."></ProductsSelection>

                  <ProductsSelection ProductLink="bathrooms/bathroom-furniture-co" ImgPath={productBathroomFurntiture} ProductTitle="The Bathroom Furniture by Burbidge" DescriptionProduct="The difference with is in the detail, and the Bathroom Furniture company examine every single detail to ensure that the end product is absolutely immaculate."></ProductsSelection>

                  <ProductsSelection ProductLink="bathrooms/keuco" ImgPath={productKeuco} ProductTitle="Keuco" DescriptionProduct="Keuco has grown from being the original specialist and market leader for high quality bathroom accessories."></ProductsSelection>

                  <ProductsSelection ProductLink="bathrooms/matki" ImgPath={productMatki} ProductTitle="Matki" DescriptionProduct="Matki is a well-known brand within luxury bathroom design, carrying an excellent reputation for designing and manufacturing high-quality Shower Enclosures, Shower Trays and Brassware."></ProductsSelection>

                  <ProductsSelection ProductLink="bathrooms/vitra" ImgPath={productVitra} ProductTitle="VitrA" DescriptionProduct="VitrA’s aim is simple – Continuously improve creativity, comfort, sustainability and bathroom aesthetics."></ProductsSelection>        

                  <ProductsSelection ProductLink="bathrooms/ashton-and-bently" ImgPath={productAshton} ProductTitle="Ashton &amp; Bently" DescriptionProduct="At Ashton and Bentley we have brought back time-honoured skills by designing and manufacturing our entire freestanding bath collection in our English factory."></ProductsSelection>     

                </div>
          
          </div>
    </ProductsSection>

    <Cta></Cta>



  </Layout>
)

export default Kitckens


