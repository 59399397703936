import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

import Button from "../Buttons/cta-button"

const Secondary = styled.div`
  display:block;
  width:100%;
  height:auto;
  overflow:hidden;
  text-align:center;
  margin:0 auto;
  margin-top:60px;
  margin-bottom:60px;
  max-width:800px;
`
const SubTitle = styled.h3`
  width:100%;
  padding-bottom:10px;
  height:auto;
  overflow:hidden;
  text-align:center;
  margin:0 auto;
  margin-bottom:10px;
  ${media.greaterThan("medium") `
    width:370px;
  `}
`

const FullCenter = () => (
    <Secondary>
        <SubTitle>About Acaelia Home Design</SubTitle>
        <hr className="small center"></hr>
        <p>We design, supply and install luxury kitchen and bathrooms throughout the Macclesfield / Cheshire region.</p>

        <p>We partner with industry leading kitchen and bathroom manufacturers, and collaborate with world renowned designers to deliver to you the highest quality products, with an in house fitting team of exceptional standard.</p>

        <p>Come and visit our award winning Macclesfield showroom to see how we can assist you on your next project.</p>

        <p><i>Acaelia Home Design – Macclesfield’s Luxury Kitchen and Bathroom Showroom</i></p>
        <p><i>KBB Review ‘Showroom of The Month 2019’</i></p>

        <Button pageTo="/about-us" ButtonText="About us"></Button>
    </Secondary>
)

export default FullCenter


