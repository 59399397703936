import React from "react"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import FullCenter from "../../../components/text/fullCenterNoBtn"
import Gallery from "../../../components/gallery/gallery"
import Hero from "../../../components/hero/hero"

import HeroImg from "../../../images/appliances/heroImg2.jpg"
import Img from "../../../images/appliances/siemens/1.jpg"
import Img2 from "../../../images/appliances/siemens/2.jpg"
import Img3 from "../../../images/appliances/siemens/3.jpg"
import Img4 from "../../../images/appliances/siemens/4.jpg"
import Img5 from "../../../images/appliances/siemens/5.jpg"
import Img6 from "../../../images/appliances/siemens/6.jpg"
import Img7 from "../../../images/appliances/siemens/7.jpg"
import Img8 from "../../../images/appliances/siemens/8.jpg"
import Img9 from "../../../images/appliances/siemens/9.jpg"
import Img10 from "../../../images/appliances/siemens/10.jpg"
import Img11 from "../../../images/appliances/siemens/11.jpg"


const Siemens = () => (
    <Layout>
    <SEO title="Siemens" />

    <Hero Short ImgPath={HeroImg} TopTitle="Enter a World of Exciting Opportunities" MainTitle="Siemens" SubTitle=""></Hero>

    <div className="container">
      <FullCenter Title="Siemens" Description="With excellent technology, unbelievable product innovation, Siemens proudly answer to many of the social and economical challenges of the 21st Century.<br></br>The company embody the rich tradition of innovation and product development. Combining the quality of the German engineering industry, with excellent product performance and visionary ideas. Siemens continue to be the most sought-after home appliance brand in the world.<br></br>Siemens-. A Life Less ordinary"/>

    <div className="container flex">

      <Gallery ImgPath={Img}></Gallery>
      <Gallery ImgPath={Img2}></Gallery>
      <Gallery ImgPath={Img3}></Gallery>
      <Gallery ImgPath={Img4}></Gallery>
      <Gallery ImgPath={Img5}></Gallery>
      <Gallery ImgPath={Img6}></Gallery>
      <Gallery ImgPath={Img7}></Gallery>
      <Gallery ImgPath={Img8}></Gallery>
      <Gallery ImgPath={Img9}></Gallery>
      <Gallery ImgPath={Img10}></Gallery>
      <Gallery ImgPath={Img11}></Gallery>
    
    </div>
    
    
    </div>


  </Layout>
)

export default Siemens