import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Gallery from "../../components/gallery/gallery"
import FullCenter from "../../components/text/fullCenterBathrooms"
import Hero from "../../components/hero/hero"

import HeroImg from "../../images/bathrooms/vitra/hero.jpg"
import Img from "../../images/bathrooms/vitra/1.jpg"
import Img2 from "../../images/bathrooms/vitra/2.jpg"
import Img3 from "../../images/bathrooms/vitra/3.jpg"
import Img4 from "../../images/bathrooms/vitra/4.jpg"
import Img5 from "../../images/bathrooms/vitra/5.jpg"
import Img6 from "../../images/bathrooms/vitra/6.jpg"
import Img7 from "../../images/bathrooms/vitra/7.jpg"
import Img8 from "../../images/bathrooms/vitra/8.jpg"
import Img9 from "../../images/bathrooms/vitra/9.jpg"
import Img10 from "../../images/bathrooms/vitra/10.jpg"
import Img11 from "../../images/bathrooms/vitra/11.jpg"
import Img12 from "../../images/bathrooms/vitra/12.jpg"
import Img13 from "../../images/bathrooms/vitra/13.jpg"
import Img14 from "../../images/bathrooms/vitra/14.jpg"
import Img15 from "../../images/bathrooms/vitra/15.jpg"
import Img16 from "../../images/bathrooms/vitra/16.jpg"
import Img17 from "../../images/bathrooms/vitra/17.jpg"
import Img18 from "../../images/bathrooms/vitra/18.jpg"
import Img19 from "../../images/bathrooms/vitra/19.jpg"
import Img20 from "../../images/bathrooms/vitra/20.jpg"
import Img21 from "../../images/bathrooms/vitra/21.jpg"
import Img22 from "../../images/bathrooms/vitra/22.jpg"
import Img23 from "../../images/bathrooms/vitra/23.jpg"
import Img24 from "../../images/bathrooms/vitra/24.jpg"
const Vitra = () => (
  <Layout>
    <SEO title="VitrA" />

    <Hero Short ImgPath={HeroImg} TopTitle="" MainTitle="VitrA" SubTitle=""></Hero>

    <div className="container">
    <FullCenter Title="Award winning International Brand." Description="Due to the quality of VitrA’s product range, the company has been the proud recipient of many prestigious awards such as Elle Décor Award, Good Design Award, Red Dot Designed no less than three IF Product design awards for excellence in product and design quality.<br></br>It is VitrA’s commitment to creativity and innovation which sees them house their very own design centre in the very heart of Europe.<br></br>VitrA’s aim is simple – Continuously improve creativity, comfort, sustainability and bathroom aesthetics. It is this concept that is helping VitrA transform the traditional concept of the bathroom, and helping reshape the culture, art and lifestyle of modern living."/>

    <div className="flex">
        <Gallery ImgPath={Img}></Gallery>
        <Gallery ImgPath={Img2}></Gallery>
        <Gallery ImgPath={Img3}></Gallery>
        <Gallery ImgPath={Img4}></Gallery>
        <Gallery ImgPath={Img5}></Gallery>
        <Gallery ImgPath={Img6}></Gallery>
        <Gallery ImgPath={Img7}></Gallery>
        <Gallery ImgPath={Img8}></Gallery>
        <Gallery ImgPath={Img9}></Gallery>
        <Gallery ImgPath={Img10}></Gallery>
        <Gallery ImgPath={Img11}></Gallery>
        <Gallery ImgPath={Img12}></Gallery>
        <Gallery ImgPath={Img13}></Gallery>
        <Gallery ImgPath={Img14}></Gallery>
        <Gallery ImgPath={Img15}></Gallery>
        <Gallery ImgPath={Img16}></Gallery>
        <Gallery ImgPath={Img17}></Gallery>
        <Gallery ImgPath={Img18}></Gallery>
        <Gallery ImgPath={Img19}></Gallery>
        <Gallery ImgPath={Img20}></Gallery>
        <Gallery ImgPath={Img21}></Gallery>
        <Gallery ImgPath={Img22}></Gallery>
        <Gallery ImgPath={Img23}></Gallery>
        <Gallery ImgPath={Img24}></Gallery>
      </div>
    </div>

  </Layout>
)

export default Vitra


