import { Link } from "gatsby"
import styled from "styled-components"
import React from "react"
import media from "styled-media-query"

import Img from "../../images/global/logo.png"

const PrimaryLogo = styled.div`
    width:150px;        
    height:50px;
    display:inline-block;
    float:left;
    background-image: url(${Img});
    background-size:contain;
    background-repeat:no-repeat;
    margin-top:10px;
    margin-left:10px;
    ${media.greaterThan("medium") `
        width:190px;  
        height:50px;
    `}
    ${media.greaterThan("large") `
        width:300px;  
        height:70px;
        margin-top:0px;
        margin-left:0px;
    `}
`

const LogoHeader = () => (

    <Link to="/">
        <PrimaryLogo></PrimaryLogo>
    </Link>

)

export default LogoHeader