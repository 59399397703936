module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"domain.com","short_name":"domain.com","start_url":"/","background_color":"#ffffff","theme_color":"#1abc9c","display":"minimal-ui","icon":"src/images/favicon.png","icons":[{"src":"favicon-io/icon-512x512.png","sizes":"512x512","type":"image/png"},{"src":"favicon-io/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicon-io/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"favicon-io/favicon-16x16.png","sizes":"16x16","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-127170751-1","head":false,"anonymize":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
