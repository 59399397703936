import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Gallery from "../../components/gallery/gallery"
import FullCenter from "../../components/text/fullCenterBathrooms"
import Hero from "../../components/hero/hero"

import HeroImg from "../../images/bathrooms/bathroom-furniture-co/hero.jpg"
import Img from "../../images/bathrooms/bathroom-furniture-co/1.jpg"
import Img2 from "../../images/bathrooms/bathroom-furniture-co/2.jpg"
import Img3 from "../../images/bathrooms/bathroom-furniture-co/3.jpg"
import Img4 from "../../images/bathrooms/bathroom-furniture-co/4.jpg"
import Img5 from "../../images/bathrooms/bathroom-furniture-co/5.jpg"
import Img6 from "../../images/bathrooms/bathroom-furniture-co/6.jpg"
import Img7 from "../../images/bathrooms/bathroom-furniture-co/7.jpg"
import Img8 from "../../images/bathrooms/bathroom-furniture-co/8.jpg"
import Img9 from "../../images/bathrooms/bathroom-furniture-co/9.jpg"
import Img10 from "../../images/bathrooms/bathroom-furniture-co/10.jpg"
import Img11 from "../../images/bathrooms/bathroom-furniture-co/11.jpg"
import Img12 from "../../images/bathrooms/bathroom-furniture-co/12.jpg"
import Img13 from "../../images/bathrooms/bathroom-furniture-co/13.jpg"
import Img14 from "../../images/bathrooms/bathroom-furniture-co/14.jpg"
import Img15 from "../../images/bathrooms/bathroom-furniture-co/15.jpg"
import Img16 from "../../images/bathrooms/bathroom-furniture-co/16.jpg"
import Img17 from "../../images/bathrooms/bathroom-furniture-co/17.jpg"
import Img18 from "../../images/bathrooms/bathroom-furniture-co/18.jpg"
import Img19 from "../../images/bathrooms/bathroom-furniture-co/19.jpg"
import Img20 from "../../images/bathrooms/bathroom-furniture-co/20.jpg"
import Img21 from "../../images/bathrooms/bathroom-furniture-co/21.jpg"
import Img22 from "../../images/bathrooms/bathroom-furniture-co/22.jpg"
import Img23 from "../../images/bathrooms/bathroom-furniture-co/23.jpg"
import Img24 from "../../images/bathrooms/bathroom-furniture-co/24.jpg"

const FurnitureCo = () => (
  <Layout>
    <SEO title="The Bathroom Furniture Co By Burbidge" />

    <Hero Short ImgPath={HeroImg} TopTitle="" MainTitle="The Bathroom Furniture Co By Burbidge" SubTitle=""></Hero>

    <div className="container">
      <FullCenter Title="Craft Carve, Create." Description="Since 1857, That Bathroom Furniture Company have been creating timber masterpieces the most highly acclaimed craftsmen would be proud to call their own. <br></br>The Bathroom Furniture Company collection can offer you a different, undeniable spirt to your Bathroom. Whether it be from the free standing or modular range, with the Bathroom Furniture Company, you are sure to create a unique feel to your home like no other.<br></br>The difference with is in the detail, and the Bathroom Furniture company examine every single detail to ensure that the end product is absolutely immaculate.<br></br>With such a wide range of cabinet’s, colours, and design colours, The Bathroom Furniture collection can be designed into any bathroom environment and will truly provide you with the deign that you deserve."/>

      <div className="flex">
        <Gallery ImgPath={Img}></Gallery>
        <Gallery ImgPath={Img2}></Gallery>
        <Gallery ImgPath={Img3}></Gallery>
        <Gallery ImgPath={Img4}></Gallery>
        <Gallery ImgPath={Img5}></Gallery>
        <Gallery ImgPath={Img6}></Gallery>
        <Gallery ImgPath={Img7}></Gallery>
        <Gallery ImgPath={Img8}></Gallery>
        <Gallery ImgPath={Img9}></Gallery>
        <Gallery ImgPath={Img10}></Gallery>
        <Gallery ImgPath={Img11}></Gallery>
        <Gallery ImgPath={Img12}></Gallery>
        <Gallery ImgPath={Img13}></Gallery>
        <Gallery ImgPath={Img14}></Gallery>
        <Gallery ImgPath={Img15}></Gallery>
        <Gallery ImgPath={Img16}></Gallery>
        <Gallery ImgPath={Img17}></Gallery>
        <Gallery ImgPath={Img18}></Gallery>
        <Gallery ImgPath={Img19}></Gallery>
        <Gallery ImgPath={Img20}></Gallery>
        <Gallery ImgPath={Img21}></Gallery>
        <Gallery ImgPath={Img22}></Gallery>
        <Gallery ImgPath={Img23}></Gallery>
        <Gallery ImgPath={Img24}></Gallery>
        </div>
    </div>

  </Layout>
)

export default FurnitureCo


