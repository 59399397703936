import React from "react"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import Hero from "../../../components/hero/hero"
import FullCenter from "../../../components/text/fullCenterKitchens"
import Gallery from "../../../components/gallery/gallery"

import HeroImg from "../../../images/kitchen/H/Hero.jpg"
import Img from "../../../images/kitchen/H/kitchenH1.jpg"
import Img2 from "../../../images/kitchen/H/kitchenH2.jpg"
import Img3 from "../../../images/kitchen/H/kitchenH3.jpg"
import Img4 from "../../../images/kitchen/H/kitchenH4.jpg"
import Img5 from "../../../images/kitchen/H/kitchenH5.jpg"
import Img6 from "../../../images/kitchen/H/kitchenH6.jpg"
import Img7 from "../../../images/kitchen/H/kitchenH7.jpg"
import Img8 from "../../../images/kitchen/H/kitchenH8.jpg"
import Img9 from "../../../images/kitchen/H/kitchenH9.jpg"
import Img10 from "../../../images/kitchen/H/kitchenH10.jpg"
import Img11 from "../../../images/kitchen/H/kitchenH11.jpg"
import Img12 from "../../../images/kitchen/H/kitchenH12.jpg"
import Img13 from "../../../images/kitchen/H/kitchenH13.jpg"
import Img14 from "../../../images/kitchen/H/kitchenH14.jpg"
import Img15 from "../../../images/kitchen/H/kitchenH15.jpg"
import Img16 from "../../../images/kitchen/H/kitchenH16.jpg"
import Img17 from "../../../images/kitchen/H/kitchenH17.jpg"
import Img18 from "../../../images/kitchen/H/kitchenH18.jpg"
import Img19 from "../../../images/kitchen/H/kitchenH19.jpg"
import Img20 from "../../../images/kitchen/H/kitchenH20.jpg"
import Img21 from "../../../images/kitchen/H/kitchenH21.jpg"
import Img22 from "../../../images/kitchen/H/kitchenH22.jpg"
import Img23 from "../../../images/kitchen/H/kitchenH23.jpg"
import Img24 from "../../../images/kitchen/H/kitchenH24.jpg"
import Img25 from "../../../images/kitchen/H/kitchenH25.jpg"
import Img26 from "../../../images/kitchen/H/kitchenH26.jpg"
import Img27 from "../../../images/kitchen/H/kitchenH27.jpg"
import Img28 from "../../../images/kitchen/H/kitchenH28.jpg"
import Img30 from "../../../images/kitchen/H/kitchenH30.jpg"
import Img31 from "../../../images/kitchen/H/kitchenH31.jpg"
import Img32 from "../../../images/kitchen/H/kitchenH32.jpg"
import Img33 from "../../../images/kitchen/H/kitchenH33.jpg"
import Img34 from "../../../images/kitchen/H/kitchenH34.jpg"
import Img35 from "../../../images/kitchen/H/kitchenH35.jpg"
import Img36 from "../../../images/kitchen/H/kitchenH36.jpg"
import Img37 from "../../../images/kitchen/H/kitchenH37.jpg"
import Img38 from "../../../images/kitchen/H/kitchenH38.jpg"
import Img39 from "../../../images/kitchen/H/kitchenH39.jpg"
import Img40 from "../../../images/kitchen/H/kitchenH40.jpg"
import Img41 from "../../../images/kitchen/H/kitchenH41.jpg"
import Img42 from "../../../images/kitchen/H/kitchenH42.jpg"
import Img43 from "../../../images/kitchen/H/kitchenH43.jpg"
import Img44 from "../../../images/kitchen/H/kitchenH44.jpg"
import Img45 from "../../../images/kitchen/H/kitchenH45.jpg"
import Img46 from "../../../images/kitchen/H/kitchenH46.jpg"
import Img47 from "../../../images/kitchen/H/kitchenH47.jpg"
import Img48 from "../../../images/kitchen/H/kitchenH48.jpg"
import Img49 from "../../../images/kitchen/H/kitchenH49.jpg"
import Img50 from "../../../images/kitchen/H/kitchenH50.jpg"
import Img51 from "../../../images/kitchen/H/kitchenH51.jpg"
import Img52 from "../../../images/kitchen/H/kitchenH52.jpg"
import Img53 from "../../../images/kitchen/H/kitchenH53.jpg"
import Img54 from "../../../images/kitchen/H/kitchenH54.jpg"
import Img55 from "../../../images/kitchen/H/kitchenH55.jpg"
import Img56 from "../../../images/kitchen/H/kitchenH56.jpg"
import Img57 from "../../../images/kitchen/H/kitchenH57.jpg"
import Img58 from "../../../images/kitchen/H/kitchenH58.jpg"
import Img59 from "../../../images/kitchen/H/kitchenH59.jpg"
import Img60 from "../../../images/kitchen/H/kitchenH60.jpg"
import Img61 from "../../../images/kitchen/H/kitchenH61.jpg"
import Img62 from "../../../images/kitchen/H/kitchenH62.jpg"
import Img63 from "../../../images/kitchen/H/kitchenH63.jpg"
import Img64 from "../../../images/kitchen/H/kitchenH64.jpg"
import Img65 from "../../../images/kitchen/H/kitchenH65.jpg"


const HandlelessKitchen = () => (
  <Layout>
    <SEO title="Handlesless Kitchen Ranges" />

    <Hero Short ImgPath={HeroImg} TopTitle="" MainTitle="Handlesless Kitchens" SubTitle=""></Hero>

    <div className="container">
      <FullCenter Title="What is a handleless kitchen?" Description="Our handleless kitchen ranges feature our exclusive and unique Handle Rail system, available in 28 colours. You can choose from a gloss, textured or super smooth finish. We offer choices to allow you to be as individual and unique with your new kitchen as you dare to be."/>

    
      <div className="flex">
        <Gallery ImgPath={Img}></Gallery>
        <Gallery ImgPath={Img2}></Gallery>
        <Gallery ImgPath={Img3}></Gallery>
        <Gallery ImgPath={Img4}></Gallery>
        <Gallery ImgPath={Img5}></Gallery>
        <Gallery ImgPath={Img6}></Gallery>
        <Gallery ImgPath={Img7}></Gallery>
        <Gallery ImgPath={Img8}></Gallery>
        <Gallery ImgPath={Img9}></Gallery>
        <Gallery ImgPath={Img10}></Gallery>
        <Gallery ImgPath={Img11}></Gallery>
        <Gallery ImgPath={Img12}></Gallery>
        <Gallery ImgPath={Img13}></Gallery>
        <Gallery ImgPath={Img14}></Gallery>
        <Gallery ImgPath={Img15}></Gallery>
        <Gallery ImgPath={Img16}></Gallery>
        <Gallery ImgPath={Img17}></Gallery>
        <Gallery ImgPath={Img18}></Gallery>
        <Gallery ImgPath={Img19}></Gallery>
        <Gallery ImgPath={Img20}></Gallery>
        <Gallery ImgPath={Img21}></Gallery>
        <Gallery ImgPath={Img22}></Gallery>
        <Gallery ImgPath={Img23}></Gallery>
        <Gallery ImgPath={Img24}></Gallery>
        <Gallery ImgPath={Img25}></Gallery>
        <Gallery ImgPath={Img26}></Gallery>
        <Gallery ImgPath={Img27}></Gallery>
        <Gallery ImgPath={Img28}></Gallery>
        <Gallery ImgPath={Img30}></Gallery>
        <Gallery ImgPath={Img31}></Gallery>
        <Gallery ImgPath={Img32}></Gallery>
        <Gallery ImgPath={Img33}></Gallery>
        <Gallery ImgPath={Img34}></Gallery>
        <Gallery ImgPath={Img35}></Gallery>
        <Gallery ImgPath={Img36}></Gallery>
        <Gallery ImgPath={Img37}></Gallery>
        <Gallery ImgPath={Img38}></Gallery>
        <Gallery ImgPath={Img39}></Gallery>
        <Gallery ImgPath={Img40}></Gallery>
        <Gallery ImgPath={Img41}></Gallery>
        <Gallery ImgPath={Img42}></Gallery>
        <Gallery ImgPath={Img43}></Gallery>
        <Gallery ImgPath={Img44}></Gallery>
        <Gallery ImgPath={Img45}></Gallery>
        <Gallery ImgPath={Img46}></Gallery>
        <Gallery ImgPath={Img47}></Gallery>
        <Gallery ImgPath={Img48}></Gallery>
        <Gallery ImgPath={Img49}></Gallery>
        <Gallery ImgPath={Img50}></Gallery>
        <Gallery ImgPath={Img51}></Gallery>
        <Gallery ImgPath={Img52}></Gallery>
        <Gallery ImgPath={Img53}></Gallery>
        <Gallery ImgPath={Img54}></Gallery>
        <Gallery ImgPath={Img55}></Gallery>
        <Gallery ImgPath={Img56}></Gallery>
        <Gallery ImgPath={Img57}></Gallery>
        <Gallery ImgPath={Img58}></Gallery>
        <Gallery ImgPath={Img59}></Gallery>
        <Gallery ImgPath={Img60}></Gallery>
        <Gallery ImgPath={Img61}></Gallery>
        <Gallery ImgPath={Img62}></Gallery>
        <Gallery ImgPath={Img63}></Gallery>
        <Gallery ImgPath={Img64}></Gallery>
        <Gallery ImgPath={Img65}></Gallery>
      </div>
    </div>

  </Layout>
)

export default HandlelessKitchen


