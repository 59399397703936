// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-bathrooms-js": () => import("../src/pages/bathrooms.js" /* webpackChunkName: "component---src-pages-bathrooms-js" */),
  "component---src-pages-bathrooms-ashton-and-bently-js": () => import("../src/pages/bathrooms/ashton-and-bently.js" /* webpackChunkName: "component---src-pages-bathrooms-ashton-and-bently-js" */),
  "component---src-pages-bathrooms-axor-js": () => import("../src/pages/bathrooms/axor.js" /* webpackChunkName: "component---src-pages-bathrooms-axor-js" */),
  "component---src-pages-bathrooms-bathroom-furniture-co-js": () => import("../src/pages/bathrooms/bathroom-furniture-co.js" /* webpackChunkName: "component---src-pages-bathrooms-bathroom-furniture-co-js" */),
  "component---src-pages-bathrooms-burlington-js": () => import("../src/pages/bathrooms/burlington.js" /* webpackChunkName: "component---src-pages-bathrooms-burlington-js" */),
  "component---src-pages-bathrooms-hansgrohe-js": () => import("../src/pages/bathrooms/hansgrohe.js" /* webpackChunkName: "component---src-pages-bathrooms-hansgrohe-js" */),
  "component---src-pages-bathrooms-keuco-js": () => import("../src/pages/bathrooms/keuco.js" /* webpackChunkName: "component---src-pages-bathrooms-keuco-js" */),
  "component---src-pages-bathrooms-kohler-js": () => import("../src/pages/bathrooms/kohler.js" /* webpackChunkName: "component---src-pages-bathrooms-kohler-js" */),
  "component---src-pages-bathrooms-matki-js": () => import("../src/pages/bathrooms/matki.js" /* webpackChunkName: "component---src-pages-bathrooms-matki-js" */),
  "component---src-pages-bathrooms-perrin-and-rowe-js": () => import("../src/pages/bathrooms/perrin-and-rowe.js" /* webpackChunkName: "component---src-pages-bathrooms-perrin-and-rowe-js" */),
  "component---src-pages-bathrooms-victoria-albert-js": () => import("../src/pages/bathrooms/victoria+albert.js" /* webpackChunkName: "component---src-pages-bathrooms-victoria-albert-js" */),
  "component---src-pages-bathrooms-villeroy-and-boch-js": () => import("../src/pages/bathrooms/villeroy-and-boch.js" /* webpackChunkName: "component---src-pages-bathrooms-villeroy-and-boch-js" */),
  "component---src-pages-bathrooms-vitra-js": () => import("../src/pages/bathrooms/vitra.js" /* webpackChunkName: "component---src-pages-bathrooms-vitra-js" */),
  "component---src-pages-contact-us-js": () => import("../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kitchens-js": () => import("../src/pages/kitchens.js" /* webpackChunkName: "component---src-pages-kitchens-js" */),
  "component---src-pages-kitchens-appliances-js": () => import("../src/pages/kitchens/appliances.js" /* webpackChunkName: "component---src-pages-kitchens-appliances-js" */),
  "component---src-pages-kitchens-appliances-siemens-js": () => import("../src/pages/kitchens/appliances/siemens.js" /* webpackChunkName: "component---src-pages-kitchens-appliances-siemens-js" */),
  "component---src-pages-kitchens-appliances-smeg-js": () => import("../src/pages/kitchens/appliances/smeg.js" /* webpackChunkName: "component---src-pages-kitchens-appliances-smeg-js" */),
  "component---src-pages-kitchens-masterclass-kitchen-ranges-js": () => import("../src/pages/kitchens/masterclass-kitchen-ranges.js" /* webpackChunkName: "component---src-pages-kitchens-masterclass-kitchen-ranges-js" */),
  "component---src-pages-kitchens-masterclass-kitchen-ranges-classic-kitchen-range-js": () => import("../src/pages/kitchens/masterclass-kitchen-ranges/classic-kitchen-range.js" /* webpackChunkName: "component---src-pages-kitchens-masterclass-kitchen-ranges-classic-kitchen-range-js" */),
  "component---src-pages-kitchens-masterclass-kitchen-ranges-handleless-kitchen-range-js": () => import("../src/pages/kitchens/masterclass-kitchen-ranges/handleless-kitchen-range.js" /* webpackChunkName: "component---src-pages-kitchens-masterclass-kitchen-ranges-handleless-kitchen-range-js" */),
  "component---src-pages-kitchens-masterclass-kitchen-ranges-modern-kitchen-range-js": () => import("../src/pages/kitchens/masterclass-kitchen-ranges/modern-kitchen-range.js" /* webpackChunkName: "component---src-pages-kitchens-masterclass-kitchen-ranges-modern-kitchen-range-js" */),
  "component---src-pages-kitchens-masterclass-kitchen-ranges-shaker-kitchen-range-js": () => import("../src/pages/kitchens/masterclass-kitchen-ranges/shaker-kitchen-range.js" /* webpackChunkName: "component---src-pages-kitchens-masterclass-kitchen-ranges-shaker-kitchen-range-js" */),
  "component---src-pages-kitchens-worktops-js": () => import("../src/pages/kitchens/worktops.js" /* webpackChunkName: "component---src-pages-kitchens-worktops-js" */),
  "component---src-pages-kitchens-worktops-dekton-js": () => import("../src/pages/kitchens/worktops/dekton.js" /* webpackChunkName: "component---src-pages-kitchens-worktops-dekton-js" */),
  "component---src-pages-kitchens-worktops-sensa-premium-collection-js": () => import("../src/pages/kitchens/worktops/sensa-premium-collection.js" /* webpackChunkName: "component---src-pages-kitchens-worktops-sensa-premium-collection-js" */),
  "component---src-pages-kitchens-worktops-silestone-js": () => import("../src/pages/kitchens/worktops/silestone.js" /* webpackChunkName: "component---src-pages-kitchens-worktops-silestone-js" */),
  "component---src-pages-laura-ashley-bedrooms-js": () => import("../src/pages/laura-ashley-bedrooms.js" /* webpackChunkName: "component---src-pages-laura-ashley-bedrooms-js" */),
  "component---src-pages-little-greene-paint-js": () => import("../src/pages/little-greene-paint.js" /* webpackChunkName: "component---src-pages-little-greene-paint-js" */),
  "component---src-pages-privacy-policy-js": () => import("../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-ted-todd-flooring-js": () => import("../src/pages/ted-todd-flooring.js" /* webpackChunkName: "component---src-pages-ted-todd-flooring-js" */),
  "component---src-pages-terms-of-use-js": () => import("../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-testimonals-js": () => import("../src/pages/testimonals.js" /* webpackChunkName: "component---src-pages-testimonals-js" */)
}

