import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import FullCenter from "../../../components/text/fullCenterNoBtn"
import Gallery from "../../../components/gallery/gallery"
import Hero from "../../../components/hero/hero"

import HeroBg from "../../../images/worktops/sensa/hero.jpg"
import Img from "../../../images/worktops/sensa/1.jpg"
import Img2 from "../../../images/worktops/sensa/2.jpg"
import Img3 from "../../../images/worktops/sensa/3.jpg"
import Img4 from "../../../images/worktops/sensa/4.jpg"

const Sensa = () => (
    <Layout>
    <SEO title="Sensa premium collection" />

    <Hero Short ImgPath={HeroBg} TopTitle="If you can think it, We can design it" MainTitle="Sensa premium collection" SubTitle=""></Hero>

    <div className="container">
      <FullCenter Title="Sensa premium collection" Description="What epitomises a high-quality kitchen like a solid granite kitchen?<br></br>Our Sensa Premium collection will offer you a beautiful, maintenance free granite worktop which simply cannot fail to impress.<br></br>Sourced direct from Brazil and India, and chosen from strict criteria of aesthetic excellence, our Sensa range has resulted in unique, elegant surfaces with an exotic yet subtle touch."/>

    <div className="container flex">

      <Gallery ImgPath={Img}></Gallery>
      <Gallery ImgPath={Img2}></Gallery>
      <Gallery ImgPath={Img3}></Gallery>
      <Gallery ImgPath={Img4}></Gallery>
    
    </div>
    
    
    </div>


  </Layout>
)

export default Sensa