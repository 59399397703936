import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Gallery from "../../components/gallery/gallery"
import FullCenter from "../../components/text/fullCenterBathrooms"
import Hero from "../../components/hero/hero"

import HeroImg from "../../images/bathrooms/keuco/hero.jpg"
import Img from "../../images/bathrooms/keuco/1.jpg"
import Img2 from "../../images/bathrooms/keuco/2.jpg"
import Img3 from "../../images/bathrooms/keuco/3.jpg"
import Img4 from "../../images/bathrooms/keuco/4.jpg"
import Img5 from "../../images/bathrooms/keuco/5.jpg"
import Img6 from "../../images/bathrooms/keuco/6.jpg"
import Img7 from "../../images/bathrooms/keuco/7.jpg"
import Img8 from "../../images/bathrooms/keuco/8.jpg"
import Img9 from "../../images/bathrooms/keuco/9.jpg"
import Img10 from "../../images/bathrooms/keuco/10.jpg"
import Img11 from "../../images/bathrooms/keuco/11.jpg"
import Img12 from "../../images/bathrooms/keuco/12.jpg"

const Keuco = () => (
  <Layout>
    <SEO title="Keuco" />

    <Hero Short ImgPath={HeroImg} TopTitle="" MainTitle="Keuco" SubTitle=""></Hero>


    <div className="container">
    <FullCenter Title="Innovative accessories and bathroom furniture" Description="Since its foundation in 1953, Keuco has grown from being the original specialist and market leader for high quality bathroom accessories to proudly presenting itself as a full range supplier of mirror cabinets, accessories, fittings and furnishings.<br></br>Keuco’s innovative designs not only make their products the most desirable on the market, but the quality of the product firmly positions them as the market leader for all bathroom accessories."/>

      <div className="flex">
        <Gallery ImgPath={Img}></Gallery>
        <Gallery ImgPath={Img2}></Gallery>
        <Gallery ImgPath={Img3}></Gallery>
        <Gallery ImgPath={Img4}></Gallery>
        <Gallery ImgPath={Img5}></Gallery>
        <Gallery ImgPath={Img6}></Gallery>
        <Gallery ImgPath={Img7}></Gallery>
        <Gallery ImgPath={Img8}></Gallery>
        <Gallery ImgPath={Img9}></Gallery>
        <Gallery ImgPath={Img10}></Gallery>
        <Gallery ImgPath={Img11}></Gallery>
        <Gallery ImgPath={Img12}></Gallery>
      </div>
    </div>

  </Layout>
)

export default Keuco


