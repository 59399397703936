import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

import Kitchen from "../../images/about/kitchen-shelford.jpg"

const AboutInfo = styled.div`
  width:100%;
  height:auto;
  display:block;
`
const Secondary = styled.div`
  display:block;
  margin:0 auto;
  margin-top:60px;
  margin-bottom:60px;
  max-width:800px;
  width:100%;
  height:auto;
  overflow:hidden;
  text-align:center;
  ${media.greaterThan("medium") `
    width:100%;
    margin-bottom:0px;
  `}
  ${media.greaterThan("large") `
    width:50%;
    margin-bottom:60px;
    text-align:left;
    display:inline-block;
  `}
`
const SubTitle = styled.h3`
  width:100%;
  padding-bottom:10px;
  height:auto;
  overflow:hidden;
  text-align:center;
  margin-bottom:10px;
  ${media.greaterThan("large") `
  text-align:left;
  width:370px;
`}
`
const Image = styled.div`
  float:right;
  display:none;
  margin-top:90px;
  background-image: url(${Kitchen});
  background-size:contain;
  background-position:center;
  background-repeat:no-repeat;
  ${media.greaterThan("medium") `
    display:block;
    width:100%;
    margin-top:20px;
    margin-bottom:30px;
    height:350px;
  `}
  ${media.greaterThan("large") `
    display:inline-block;
    width:45%;
    margin-top:90px;
  `}
`
const TextArea = styled.div`
  display:block;
  width:100%;
  overflow:hidden;
`

const FullCenter = () => (
    <AboutInfo>
      <Secondary>
        <SubTitle>About us</SubTitle>
        <hr className="small aboutHR"></hr>
        <TextArea>
          <p>Acaelia Home Design is Macclesfield’s Premium Kitchen and Bathroom showroom.</p>
          <p>We are a family run business, with true family values.</p>
          <p>We offer a one stop, one shop service where we can take care of everything from the initial kitchen or bathroom design, to a fully project managed renovation.</p>
          <p>Based in Macclesfield, Our award winning kitchen and bathroom showroom boasts over 40 beautiful life style displays, to help you truly visualise what can be achieved with your renovation, accompanied by expert advice in a relaxed environment.</p>
          <p>With over 25 highly skilled in house fitters, you can rest assured knowing that every aspect of your project is being overseen by professionals.</p>
          <p>Come and join the 1000+ happy customers who have benefited from our service.</p>
          <p>Acaelia Home Design- A proud division of NSJ Contractors Ltd</p>
       </TextArea>
    </Secondary>

    <Image></Image>
  </AboutInfo>
)

export default FullCenter


