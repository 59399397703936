import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero/hero"
import FullCenter from "../components/text/fullCenterNoBtn"
import ProductsSelection from "../components/products/allProductsSelect"
import Cta from "../components/cta/whiteCta"
import Gallery from "../components/gallery/gallery"

import HeroImg from "../images/little-greene/placeholder.jpg"
import Img from "../images/little-greene/LG1.jpg"
import Img2 from "../images/little-greene/LG2.jpg"
import Img3 from "../images/little-greene/LG3.jpg"
import Img4 from "../images/little-greene/LG4.jpg"
import Img5 from "../images/little-greene/LG5.jpg"
import Img6 from "../images/little-greene/LG6.jpg"
import Img7 from "../images/little-greene/LG7.jpg"
import Img8 from "../images/little-greene/LG8.jpg"
import Img9 from "../images/little-greene/LG9.jpg"
import Img10 from "../images/little-greene/LG10.jpg"
import Img11 from "../images/little-greene/LG11.jpg"
import Img12 from "../images/little-greene/LG12.jpg"
import Img13 from "../images/little-greene/LG13.jpg"
import Img14 from "../images/little-greene/LG14.jpg"
import Img15 from "../images/little-greene/LG15.jpg"
import Img16 from "../images/little-greene/LG16.jpg"
import Img17 from "../images/little-greene/LG17.jpg"
import Img18 from "../images/little-greene/LG18.jpg"
import Img19 from "../images/little-greene/LG19.jpg"
import Img20 from "../images/little-greene/LG20.jpg"
import Img21 from "../images/little-greene/LG21.jpg"
import Img22 from "../images/little-greene/LG22.jpg"
import Img23 from "../images/little-greene/LG23.jpg"
import Img24 from "../images/little-greene/LG24.jpg"
import Img25 from "../images/little-greene/LG25.jpg"

import productKitchen from "../images/global/product-samples/kitchenSelect.jpg"
import productBathroom from "../images/homepage/products/placeholder-bathroom.jpg"
import productFlooring from "../images/ted-todd-flooring/Product-section-hero.jpg"
import productBedrooms from "../images/homepage/products/placeholder-bedroom.jpg"
import productPaint from "../images/homepage/products/placeholder-paint.jpg"

const Titleh5 = styled.h5`
  text-transform: uppercase;
  letter-spacing: 2px;
  margin:15px 0px;
`
const Titleh1 = styled.h1`
  letter-spacing: 2px;
  margin-bottom:20px;
  margin-top:20px;
`
const ProductsSection = styled.div`
  background-color:#d6d6d6;
  width:100%;
  height:auto;
  color:black;
  padding:40px 0px;
  overflow:hidden;
`
const ColOne = styled.div`
  width:100%;
  display:inline-block;
  margin:40px 0px;
  p{
    display:block;
    overflow:hidden;
    float:left;
  }
  ${media.greaterThan("medium") `
    width:45%;
  `}
`
const ColTwo = styled.div`
  display:none;
  background-image: url(${HeroImg});
  background-size:cover;
  background-repeat:no-repeat;
  height:250px;
  float:right;
  margin:40px 0px;
  ${media.greaterThan("medium") `
    width:45%;
    display:inline-block;
  `}
`

const LauraAshley = () => (
  <Layout>
    <SEO title="Little Greene Paint" />

    <Hero Short ImgPath={HeroImg} TopTitle="Independently British paint" MainTitle="Little Greene Paint" SubTitle="Designed to suit your lifestyle, whatever the style"></Hero>


    <div className="container">
      <FullCenter Title="Little Greene Paint" 
        Description="Unrivalled depth of colour, unique character, and complex pigmentation making our paint selection the true point of difference.
        <br><br>History is deeply embedded in the roots of our Little Greene Paint and Paper range.
        
        <br><br>Little Greene first began manufacturing in 1773, on the outskirts of Manchester at one of the UK’s most ancient industrial sites for the making on paints and dye solutions.
        
        <br><br>Since then, the culture and history of Little Greene has continued to develop and grow. Despite Little Greene’s unbelievable growth, the company has stayed loyal to its valued traditions on which its success has been built, and still incorporate many of the historic materials used in the past, for the preparation of only the very highest quality paints they make today. Something Joshua Rowlands, the earliest identifiable proprietor of the works, would have been very proud of. With a vast variety of unique, traditional and contemporary colours and papers, you are sure to find your dream combinations here with our Industry Leading British Paint Manufacturer. Little Greene." />

      <div className="flex">
        <Gallery Long ImgPath={Img}></Gallery>
        <Gallery Long ImgPath={Img2}></Gallery>
        <Gallery Long ImgPath={Img3}></Gallery>
        <Gallery Long ImgPath={Img4}></Gallery>
        <Gallery Long ImgPath={Img5}></Gallery>
        <Gallery Long ImgPath={Img6}></Gallery>
        <Gallery Long ImgPath={Img7}></Gallery>
        <Gallery Long ImgPath={Img8}></Gallery>
        <Gallery Long ImgPath={Img9}></Gallery>
        <Gallery Long ImgPath={Img10}></Gallery>
        <Gallery Long ImgPath={Img11}></Gallery>
        <Gallery Long ImgPath={Img12}></Gallery>
        <Gallery Long ImgPath={Img13}></Gallery>
        <Gallery Long ImgPath={Img14}></Gallery>
        <Gallery Long ImgPath={Img15}></Gallery>
        <Gallery Long ImgPath={Img16}></Gallery>
        <Gallery Long ImgPath={Img17}></Gallery>
        <Gallery Long ImgPath={Img18}></Gallery>
        <Gallery Long ImgPath={Img19}></Gallery>
        <Gallery Long ImgPath={Img20}></Gallery>
        <Gallery Long ImgPath={Img21}></Gallery>
        <Gallery Long ImgPath={Img22}></Gallery>
        <Gallery Long ImgPath={Img23}></Gallery>
        <Gallery Long ImgPath={Img24}></Gallery>
        <Gallery Long ImgPath={Img25}></Gallery>
    </div>

    </div>
    <ProductsSection>
      <div className="container">

        <ColOne>
          <Titleh5 white>Want to see more</Titleh5>
          <Titleh1>Explore with us</Titleh1>
          <hr className="small"></hr>
        </ColOne>

        <ColTwo></ColTwo>  
      </div>

      <div className="container flex">

        <ProductsSelection productsLarger ProductLink="/kitchens/" ImgPath={productKitchen} ProductTitle="Kitchens" DescriptionProduct="A kitchen is much more than just a place to cook. It has fast become the heart of the home and the hub of family life, it will witness many family memories."></ProductsSelection>
        <ProductsSelection productsLarger ProductLink="/bathrooms/" ImgPath={productBathroom} ProductTitle="Bathrooms" DescriptionProduct="From contemporary to traditional bathrooms. Creating the perfect bathroom is our priority through a vast range of choice"></ProductsSelection>
        <ProductsSelection ProductLink="/ted-todd-flooring/" ImgPath={productFlooring} ProductTitle="Ted Todd Flooring" DescriptionProduct="At Ted Todd, the pioneering development team are constantly pushing the boundaries of what a wood floor can be. The endless research, innovation and patience has now rendered a product which is that is simply breathtaking."></ProductsSelection>
        <ProductsSelection ProductLink="/laura-ashley-bedrooms/" ImgPath={productBedrooms} ProductTitle="Laura Ashley Bedrooms" DescriptionProduct="Laura Ashley Fitted Bedrooms include two expertly developed design options, Sherborne and Pelham. Both are available in eleven painted finishes, which can be accessorised with a wide range of handles."></ProductsSelection>
        <ProductsSelection ProductLink="/little-greene-paint/" ImgPath={productPaint} ProductTitle="Little Greene Paint" DescriptionProduct="With a vast variety of unique, traditional and contemporary colours and papers, you are sure to find your dream combinations here with our Industry Leading British Paint Manufacturer, Little Greene."></ProductsSelection>
      </div>
    </ProductsSection>
    <Cta></Cta>
  </Layout>
)

export default LauraAshley


