import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

import Button from "../Buttons/cta-button"

const HeroImg = styled.div`
    width:100%;        
    height:100vh;
    display:inline-block;
    float:left;
    background-image:url(${props => props.Img});
    background-size:cover;
    background-position:center;
    margin-top:-50px;
    background-repeat:no-repeat;
    position:relative;
    ${media.greaterThan("medium") `
      height:${props => props.Short ? "70vh" : "100vh"};
      background-position: ${props => props.Short ? "bottom" : "center"};
    `}
`
const Overlay = styled.div`
    width:100%;        
    height: 100%;
    background-color:black;
    opacity:0.35;
    display:block;
    position:relative;
`
const MiddleText = styled.div`
    width:80%;
    height:auto;
    margin:0 auto;
    color:white;
    position: absolute;
    top: 30%;
    text-align:left;
    ${media.greaterThan("medium") `
      width:70%;
      top:30%;
    `}
    ${media.greaterThan("large") `
    width:50%;
    top:30%;
    `}
}
`
const Titleh5 = styled.h5`
  text-transform: uppercase;
  letter-spacing: 2px;
  margin:15px 0px;
  text-shadow: 0 0 2px #5d5c5c; /* horizontal-offset vertical-offset 'blur' colour */
  -moz-text-shadow: 0 0 2px #5d5c5c;
  -webkit-text-shadow: 0 0 2px #5d5c5c;
`
const Titleh1 = styled.h1`
  letter-spacing: 2px;
  margin-bottom:20px;
  margin-top:20px;
  text-shadow: 0 0 2px #5d5c5c; /* horizontal-offset vertical-offset 'blur' colour */
  -moz-text-shadow: 0 0 2px #5d5c5c;
  -webkit-text-shadow: 0 0 2px #5d5c5c;
`
const Titleh3 = styled.h3`
  margin-top:60px;
  clear:both;
  ${media.greaterThan("medium") `
    font-size:25px;
  `}
`

const Hero = ({TopTitle, MainTitle, SubTitle, ImgPath, Short}) => (
    <HeroImg Short={Short} Img={ImgPath}>
      <Overlay></Overlay>
        <div className="container">
          <MiddleText>
            <Titleh5 dangerouslySetInnerHTML={{ __html: TopTitle }} />
            <Titleh1 dangerouslySetInnerHTML={{ __html: MainTitle }} />
            <hr className="small"></hr>
            <Titleh3 dangerouslySetInnerHTML={{ __html: SubTitle }} />
            <div>
              <Button pageTo="/contact-us" ButtonText="Visit the Showroom"></Button>
            </div>
          </MiddleText>
        </div>
    </HeroImg>
)

export default Hero


