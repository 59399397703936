import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Layout from "../components/layout"
import SEO from "../components/seo"

const FooterTemplate = styled.div`
    margin-top:40px;
    margin-bottom:50px;
    ${media.greaterThan("large") `
        margin-top:150px;
    `}
`

const TermsOfUse = () => (
  <Layout>
    <SEO title="Terms Of Use" />
    
    <div class="container">
        <FooterTemplate>
            <h2>TERMS AND CONDITIONS OF USE</h2>
            
            <h3>Introduction</h3>

                <p>These terms and conditions apply between you, the User of this Website (including any sub-domains, unless expressly excluded by their own terms and conditions), and Acaelia Home Design, the owner and operator of this Website. Please read these terms and conditions carefully, as they affect your legal rights. Your agreement to comply with and be bound by these terms and conditions is deemed to occur upon your first use of the Website. If you do not agree to be bound by these terms and conditions, you should stop using the Website immediately.</p>

                <p>In these terms and conditions, User or Users means any third party that accesses the Website and is not either (i) employed by Acaelia Home Design and acting in the course of their employment or (ii) engaged as a consultant or otherwise providing services to Acaelia Home Design and accessing the Website in connection with the provision of such services.</p>

                <p>You must be at least 18 years of age to use this Website. By using the Website and agreeing to these terms and conditions, you represent and warrant that you are at least 18 years of age.</p>

                <p>Intellectual property and acceptable use</p>

                <p>1. All Content included on the Website, unless uploaded by Users, is the property of Acaelia Home Design, our affiliates or other relevant third parties. In these terms and conditions, Content means any text, graphics, images, audio, video, software, data compilations, page layout, underlying code and software and any other form of information capable of being stored in a computer that appears on or forms part of this Website, including any such content uploaded by Users. By continuing to use the Website you acknowledge that such Content is protected by copyright, trademarks, database rights and other intellectual property rights. Nothing on this site shall be construed as granting, by implication, estoppel, or otherwise, any license or right to use any trademark, logo or service mark displayed on the site without the owner’s prior written permission</p>

                <p>2.You may, for your own personal, non-commercial use only, do the following:</p>

                <p>a. retrieve, display and view the Content on a computer screen</p>

                <p>3.You must not otherwise reproduce, modify, copy, distribute or use for commercial purposes any Content without the written permission of Acaelia Home Design.</p>

                

                <h3>Prohibited use</h3>

                

                <p>4. You may not use the Website for any of the following purposes:</p>

                <p>a. in anyway which causes, or may cause, damage to the Website or interferes with any other person’s use or enjoyment of the Website;</p>

                <p>b. in any way which is harmful, unlawful, illegal, abusive, harassing, threatening or otherwise objectionable or in breach of any applicable law, regulation, governmental order;</p>

                <p>c. making, transmitting or storing electronic copies of Content protected by copyright without the permission of the owner.</p>

                

                <h3>Privacy Policy and Cookies Policy</h3>

                

                <p>5. Use of the Website is also governed by our Privacy Policy and Cookies Policy, which are incorporated into these terms and conditions by this reference. To view the Privacy Policy and Cookies Policy, please click on the following:</p>

                <p><a href="http://acaeliahomedesign.co.uk/privacypolicy">http://acaeliahomedesign.co.uk/privacypolicy</a> and < a href="http://acaeliahomedesign.co.uk/cookiepolicy">http://acaeliahomedesign.co.uk/cookiepolicy</a>.</p>

                

                

                <h3>Availability of the Website and disclaimers</h3>

                

                <p>6. Any online facilities, tools, services or information that Acaelia Home Design makes available through the Website (the Service) is provided “as is” and on an “as available” basis. We give no warranty that the Service will be free of defects and/or faults. To the maximum extent permitted by the law, we provide no warranties (express or implied) of fitness for a particular purpose, accuracy of information, compatibility and satisfactory quality. Acaelia Home Design is under no obligation to update i format’ on the Website.</p>

                

                <p>7. Whilst Acaelia Home Design uses reasonable endeavours to ensure that the Website is secure and free of errors, viruses and other malware, we give no warranty or guaranty in that regard and all Users take responsibility for their own security, that of their personal details and their computers.</p>

                <p>8. Acaelia Home Design accepts no liability for any disruption or non-availability of the Website.</p>

                <p>9. Acaelia Home Design reserves the right to alter, suspend or discontinue any part (or the whole of) the Website including, but not limited to, any products and/or services available. These terms and conditions shall continue to apply to any modified version of the Website unless it is expressly stated otherwise.</p>

                

                <h3>Limitation of Liability</h3>

                

                <p>10. Nothing in these terms and conditions will: (a) limit or exclude our or your liability for death or personal injury resulting from our or your negligence, as applicable; (b) limit or exclude our or your liability for fraud or fraudulent misrepresentation; or (c) limit or exclude any of our or your liabilities in any way that is not permitted under applicable law.</p>

                <p>11. We will not be liable to you in respect of any losses arising out of events beyond our reasonable control.</p>

                <p>12. To the maximum extent permitted by law, Acaelia Home Design accepts no liability for any of the following:</p>

                <p>a. any business losses, such as loss of profits, income, revenue, anticipated savings, business, contracts, goodwill or commercial opportunities;</p>

                <p>b. loss or corruption of any data, database or software;</p>

                <p>c. any special, indirect or consequential loss or damage.</p>

                

                <h3>General</h3>

                

               <p>13. You may not transfer any of your rights under these terms and conditions to any other person. We may transfer our rights under these terms and conditions where we reasonably believe your rights will not be affected.</p>

                <p>14. These terms and conditions may be varied by us from time to time. Such revised terms will apply to the Website from the date of publication. Users should check the terms and conditions regularly to ensure familiarity with the then current version.</p>

                

                <p>15. These terms and conditions together with the Privacy Policy and Cookies Policy contain the whole agreement between the parties relating to its subject matter and supersede ali prior discussions, arrangements or agreements that might have taken place in relation to the terms and conditions.</p>

                <p>16. The Contracts (Rights ofThird Parties) Act 1999 shall not apply to these terms and conditions and no third party will have any right to enforce or rely on any provision of these terms and conditions.</p>

                <p>17. If any court or competent authority fmds that any provision of these terms and conditions (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of these terms and conditions will not be affected.</p>

                <p>18. Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be deemed a waiver of that, or any other, right or remedy.</p>

                <p>19. This Agreement shall be governed by and interpreted according to the law ofEngland and Wales and all disputes arising under the Agreement (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of the English and Welsh courts.</p>

                

                <h3>Acaelia Home Design details</h3>

                

                <p>20. Acaelia Home Design is a company incorporated in England and Wales with registered number 01625 464955 whose</p>

                

                <p>registered address is Unir 4, Fence Avenue industrial estate, Macclesfield, Chesire, SKlO lLT and it operates the Website</p>

                <p><a href="http://acaeliahomedesign.co.uk/">http://acaeliahomedesign.co.uk</a>. The registered VAT number is _______________ .</p>

                <p>You can contact Acaelia Home Designs by email on enquiries@acaelia.com.</p>

            </FooterTemplate>
        </div>
    </Layout>

)

export default TermsOfUse
