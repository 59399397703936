import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

import Button from "../Buttons/cta-button"

const Secondary = styled.div`
  display:block;
  width:100%;
  height:auto;
  overflow:hidden;
  text-align:center;
  max-width:800px;
  margin:0 auto;
  margin-top:60px;
  margin-bottom:60px;
`
const SubTitle = styled.h3`
  width:100%;
  padding-bottom:10px;
  height:auto;
  overflow:hidden;
  text-align:center;
  margin:0 auto;
  margin-bottom:10px;
  ${media.greaterThan("medium") `
    width:370px;
  `}
`

const FullCenter = ({Title, Description }) => (
    <Secondary>
        <SubTitle dangerouslySetInnerHTML={{ __html: Title }} />
        <hr className="small center"></hr>
        <p dangerouslySetInnerHTML={{ __html: Description }} />

        <Button pageTo="/kitchens/masterclass-kitchen-ranges" ButtonText="Masterclass kitchen ranges"></Button>
    </Secondary>
)

export default FullCenter


