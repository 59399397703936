import React from "react"
import styled from "styled-components"
import media from "styled-media-query"


const Gallery = styled.div`
    width:100%;
    height:${props => props.Long ? "600px" : "280px"};
    display:block;
    background-color:#5d5c5c;
    background-image: url(${props => props.Img});
    background-size:cover;
    background-repeat:no-repeat;
    margin:20px 0px;
    ${media.greaterThan("medium") `
      width:${props => props.Long ? "24%" : "32%"};
      float:left;
      margin:10px 0;
    `}
`

const GallerySection = ({ImgPath, Long}) => (
    <Gallery Long={Long} Img={ImgPath} />
)

export default GallerySection


