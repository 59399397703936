import React from "react"
import styled from "styled-components"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero/hero"
import FullCenter from "../../components/text/fullCenterNoBtn"
import ProductsSelection from "../../components/products/doubleProducts"
import Cta from "../../components/cta/whiteCta"

import HeroImg from "../../images/kitchen/Hero.jpg"
import productClassic from "../../images/kitchen/classic-product.jpg"
import productShaker from "../../images/kitchen/shaker-product.jpg"
import productModern from "../../images/kitchen/modern-product.jpg"
import productH from "../../images/kitchen/H-product.jpg"

const ProductsSection = styled.div`
  background-color:white;
  width:100%;
  height:auto;
  color:black;
  padding:40px 0px;
  overflow:hidden;
`

const Kitckens = () => (
  <Layout>
    <SEO title="About us" />

    <Hero Short ImgPath={HeroImg} TopTitle="Award winning kitchens" MainTitle="Masterclass Kitchen range" SubTitle="You shouldn't have to compromise when it comes to your dream kitchen"></Hero>
    
    <div className="container">
      <FullCenter Title="Masterclass quality" Description=" Masterclass kitchen range is truly breath taking. The core values of the company are to ensure that the kitchens meet the precise standards that all of the customers have come to expect. With a delightful range of Handleless, modern, classic and traditional kitchens, there is something for everyone. <br><br>
      EKBB Editor’s Choice Kitchen Furniture -2017<br><br>
      Award Winning Kitchens<br><br>
      EKBB Editor’s Choice Kitchen Furniture – 2017 ek &amp; business Awards<br><br>
      Best Overall Kitchen Brand-2016 BKU Awards<br><br>
      Highly Commended Best Overall Kitchen Brand 2017 BKU" ButtonText="Masterclass kitchen ranges" />
    </div>

    <ProductsSection>
        <div className="container">
              <div className="flex">
                <ProductsSelection ProductLink="kitchens/masterclass-kitchen-ranges/classic-kitchen-range/" ImgPath={productClassic} ProductTitle="Classic kitchens" DescriptionProduct="Classic kitchen design is at the very heart of what we do – our Classic kitchen collection features a range of stunning traditional kitchen designs to give a beautiful country kitchen look. We excel with classic oak and painted furniture for the ultimate traditional look, country feel or classic design. Our classic kitchens are beautifully finished and offer you a variety of price points and finishes, from vinyl oak through to painted furniture."></ProductsSelection>
                <ProductsSelection ProductLink="kitchens/masterclass-kitchen-ranges/shaker-kitchen-range/" ImgPath={productShaker} ProductTitle="Shaker Kitchens" DescriptionProduct="Shaker kitchens are a timeless style that seems more popular than ever. Strong yet simple designs are featured in our shaker kitchens collection. Available in a wide selection of colours, stains and paints, these ranges can be mixed and matched to create your own unique kitchen. Shaker kitchens are characterised by uncluttered and streamlined designs that epitomize quality, style and sophistication."></ProductsSelection>
                <ProductsSelection ProductLink="kitchens/masterclass-kitchen-ranges/modern-kitchen-range/" ImgPath={productModern} ProductTitle="Modern kitchens" DescriptionProduct="Nothing represents modern kitchens better than the sleek lines and design of the flat slab kitchen door. Choice and finish are ever more important for modern living. Masterclass now offers more colours and textured finishes than ever before, that can be combined with our unique design features to further enhance your dream kitchen."></ProductsSelection>
                <ProductsSelection ProductLink="kitchens/masterclass-kitchen-ranges/handleless-kitchen-range/" ImgPath={productH} ProductTitle="Handleless kitchens" DescriptionProduct="Our H Line Handleless Kitchen range is an ultra minimal, clean lined and modern mix of gloss, textured and super smooth finishes, all blended together with a true handleless programme of furniture, allowing almost any taste in finish to be accommodated. Combined with our exclusive and unique rail system, we have options of colour and finish that provides even more scope for perfection and individuality."></ProductsSelection>
              </div>
        </div>
    </ProductsSection>

    <Cta></Cta>



  </Layout>
)

export default Kitckens


