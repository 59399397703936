import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero/hero"
import FullCenter from "../../components/text/fullCenterNoBtn"
import ProductsSelection from "../../components/products/allProductsSelect"

import HeroImg from "../../images/worktops/Hero.jpg"
import productSilestone from "../../images/worktops/silestone/1.jpg"
import productDekton from "../../images/worktops/dekton/heroImg.jpg"
import productSensa from "../../images/worktops/sensa/hero.jpg"

const Worktops = () => (
    <Layout>
    <SEO title="Worktops" />

    <Hero Short ImgPath={HeroImg} TopTitle="If you can think it, We can design it" MainTitle="Worktops" SubTitle=""></Hero>

    <div className="container">
      <FullCenter Title="Worktops" Description="Choosing a worktop to compliment you new kitchen can often be one of the hardest task of completing your kitchen design. At Imperial, we have carefully sourced industry leading products from around the world, to offer you only the best worktops to complement your new kitchen.<br><br>With a range of options including Granite, Quatz, Corian and Solid wood, to a unmatched selection of profiles, designs, accessories and fabrications, rest assured knowing that whichever worktop you choose, your worktop will be the glamourous finishing touch to your new kitchen."/>

    <div className="container flex">

      <ProductsSelection ProductLink="kitchens/worktops/silestone" ImgPath={productSilestone} ProductTitle="Silestone" DescriptionProduct="Silestone is an outstanding, market leading, innovative product made exclusively for you. Made up of 94% natural quartz, its look is simply sublime. Embeded in revolutionary anti-bacterial technology, stain resistant, and an unmatched 25 year guarantee, you need look no further for the worktop to compliment your dream kitchen."></ProductsSelection>
      <ProductsSelection ProductLink="kitchens/worktops/dekton" ImgPath={productDekton} ProductTitle="Dekton" DescriptionProduct="Dekton worktop is epitomised by innovation at the highest level. A sophisticated mixture of more than 20 minerals extracted from nature and the results have concluded a product which in envied by all. Scratch resistant, stain resistant UV resistant, with a structural density greater than granite! Dekton provides the perfect option to an ultra-contemporary feel to your kitchen."></ProductsSelection>
      <ProductsSelection ProductLink="kitchens/worktops/sensa-premium-collection" ImgPath={productSensa} ProductTitle="Sensa premium collection" DescriptionProduct="What epitomises a high-quality kitchen like a solid granite kitchen? Our Sensa Premium collection will offer you a beautiful, maintenance free granite worktop which simply cannot fail to impress. Sourced direct from Brazil and India, and chosen from strict criteria of aesthetic excellence, our Sensa range has resulted in unique, elegant surfaces with an exotic yet subtle touch."></ProductsSelection>
    
    </div>
    
    
    </div>


  </Layout>
)

export default Worktops