import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import media from "styled-media-query"

const ProductBox = styled(Link)`
    display:block;
    background-color:white;
    float:left;
    width:100%:
    height:auto;
    margin:20px 0;
    text-decoration: none;

    &:hover{
        box-shadow: 3px 3px 3px 3px #bfbfbf;
    }
    ${media.greaterThan("600px") `
        min-height:${props => props.productsLarger ? "300px" : "350px"};
    `}
    ${media.greaterThan("medium") `
        width:${props => props.productsLarger ? "49%" : "32%"};
        display:inline-block;
        margin:20px 0px;
        min-height:460px;
    `}
        
    ${media.greaterThan("large") `
        height:${props => props.productsLarger ? "380px" : "350px"};
        min-height: 390px;
    `}
`
const Img = styled.div`
    background-color:#ccc;
    background-image:url(${props => props.Img});
    background-size:cover;
    background-repeat:no-repeat;
    width:100%;
    height:170px;
    ${media.greaterThan("medium") `
        height:${props => props.productsLarger ? "250px" : "200px"};
    `}
`
const Title = styled.h3`
    color:black;
    padding:0px 10px;
    font-weight:bold;
    margin-bottom:0;
    margin-top:10px;
`
const Description = styled.p`
    color:black;
    padding:10px 10px;
    font-size:14px;
    ${media.greaterThan("medium") `
        padding:0px 10px;
    `}
`

const AllProducts = ({ProductLink, ProductTitle, DescriptionProduct, ImgPath, productsLarger}) => (
    <ProductBox productsLarger={productsLarger} to={ProductLink}>
        <Img productsLarger={productsLarger} Img={ImgPath} />
        <Title dangerouslySetInnerHTML={{ __html: ProductTitle }} />
        <Description dangerouslySetInnerHTML={{ __html: DescriptionProduct }} />
    </ProductBox>
)

export default AllProducts


