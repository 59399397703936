import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Gallery from "../../components/gallery/gallery"
import FullCenter from "../../components/text/fullCenterBathrooms"
import Hero from "../../components/hero/hero"

import HeroImg from "../../images/bathrooms/ashton/hero.jpg"
import Img from "../../images/bathrooms/ashton/1.jpg"
import Img2 from "../../images/bathrooms/ashton/2.jpg"
import Img3 from "../../images/bathrooms/ashton/3.jpg"
import Img4 from "../../images/bathrooms/ashton/4.jpg"
import Img5 from "../../images/bathrooms/ashton/5.jpg"
import Img6 from "../../images/bathrooms/ashton/6.jpg"
import Img7 from "../../images/bathrooms/ashton/7.jpg"
import Img8 from "../../images/bathrooms/ashton/8.jpg"
import Img9 from "../../images/bathrooms/ashton/9.jpg"
import Img10 from "../../images/bathrooms/ashton/10.jpg"


const Ashton = () => (
  <Layout>
    <SEO title="Ashton and Bently" />

    <Hero Short ImgPath={HeroImg} TopTitle="" MainTitle="Ashton and Bently" SubTitle=""></Hero>

    <div className="container">

      <FullCenter Title="Beautiful baths individually crafted" Description="At Ashton and Bentley we have brought back time-honoured skills by designing and manufacturing our entire freestanding bath collection in our English factory.<br><br> Beautiful baths individually crafted. This is English innovation at its best. Utilising the latest computer aided technology and the meticulous use of modern materials, together personify the essence of Ashton &amp; Bentley.<br><br> With our exclusive material Correro<sup>&trade;</sup> we have harnessed the thermal properties of organic limestone and pure white marble. This unique composite material is beautiful, hard wearing, luxurious and practical, which allows bath water to stay warmer for longer.<br><br> Our timeless baths, hand-polished by skilled craftsmen in a variety of different finishes, remind England to be proud of its manufacturing heritage."/>

      <div className="flex">
        <Gallery ImgPath={Img}></Gallery>
        <Gallery ImgPath={Img2}></Gallery>
        <Gallery ImgPath={Img3}></Gallery>
        <Gallery ImgPath={Img4}></Gallery>
        <Gallery ImgPath={Img5}></Gallery>
        <Gallery ImgPath={Img6}></Gallery>
        <Gallery ImgPath={Img7}></Gallery>
        <Gallery ImgPath={Img8}></Gallery>
        <Gallery ImgPath={Img9}></Gallery>
        <Gallery ImgPath={Img10}></Gallery>

        </div>
    </div>

  </Layout>
)

export default Ashton


