import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero/hero"
import FullCenter from "../components/text/fullCenterNoBtn"
import ProductsSelection from "../components/products/kitchenSelect"
import Cta from "../components/cta/whiteCta"

import Img from "../images/kitchen/shelford-main-min.jpg"
import productKitchens from "../images/kitchen/kitchen-product.jpg"
import productWorktops from "../images/worktops/dekton/heroImg.jpg"
import productAppliances from "../images/appliances/heroImg.jpg"

const ProductsSection = styled.div`
  background-color:#d6d6d6;
  width:100%;
  height:auto;
  color:black;
  padding:40px 0px;
  overflow:hidden;
`

const Kitckens = () => (
  <Layout>
    <SEO title="About us" />

    <Hero ImgPath={Img} TopTitle="Heart of the home" MainTitle="Kitchens, worktops and appliances" SubTitle="Improve your kitchen today"></Hero>

    <div className="container">
      <FullCenter Title="Improve your kitchen today" Description=" Over the years, the kitchen has moved from solely being a place to cook, to now being the central hub of the home. Many happy memories will take place in your kitchen, and that is why we have spent so much time and effort into refining our entire kitchen collection, so nothing but perfection is acceptable." ButtonText="Masterclass kitchen ranges" />
    </div>

    <ProductsSection>
        <div className="container">
            <h3>Lets get to the specific's...</h3>
                <div className="flex">
                  <ProductsSelection ProductLink="kitchens/masterclass-kitchen-ranges" ImgPath={productKitchens} ProductTitle="Masterclass kitchen ranges" DescriptionProduct="Masterclass is a collection of over 400 classic, shaker and modern styled doors that offer you the flexibility to design and create your dream kitchen."></ProductsSelection>
                  <ProductsSelection ProductLink="kitchens/worktops" ImgPath={productWorktops} ProductTitle="Worktops" DescriptionProduct="Your worktop will be the glamourous finishing touch to your new kitchen."></ProductsSelection>
                  <ProductsSelection ProductLink="kitchens/appliances" ImgPath={productAppliances} ProductTitle="Kitchen appliances" DescriptionProduct="A wide range of appliances that will compliment you kitchen in every way."></ProductsSelection>
                </div>
        </div>
    </ProductsSection>

    <Cta></Cta>



  </Layout>
)

export default Kitckens


