import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

import Button from "../Buttons/cta-button"

const Cta = styled.div`
    width:100%;
    height:auto;
    background-color:white;
    ${media.greaterThan("large") `
        height:100px;
    `}
`
const Text = styled.div`
    width:100%;
    float:left;
    display:inline-block;
    ${media.greaterThan("medium") `
        width:70%;  
    `}
    ${media.greaterThan("large") `
        width:60%;
    `}
`
const CtaArea = styled.div`
    width:100%;
    float:right;
    display:inline-block;
    margin-top:28px;
    margin-bottom:30px;
    ${media.greaterThan("medium") `
        width:21%;  
    `}
    ${media.greaterThan("large") `
        width:30%;
    `}
`
const Titleh5 = styled.h5`
  text-transform: uppercase;
  letter-spacing: 2px;
  margin:0;
  padding:25px 0 0 0;
`
const Titleh4 = styled.h4`
  clear:both;
  font-size:14px;
  font-weight:300;
  padding:0;
  margin:0;
    ${media.greaterThan("large") `
    font-size:18px;
    `}
`

const WhiteCta = () => (
   <Cta>
       <div className="container">
            <Text>
                <Titleh5 white>Get in touch</Titleh5>
                <Titleh4>If you have seen something you like please dont hesitate to get in touch with us.</Titleh4>
            </Text>
            <CtaArea>
                <Button pageTo="/contact-us" ButtonText="Contact us"></Button>
            </CtaArea>
       </div>
   </Cta>
)

export default WhiteCta