import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Gallery from "../../components/gallery/gallery"
import FullCenter from "../../components/text/fullCenterBathrooms"
import Hero from "../../components/hero/hero"

import HeroImg from "../../images/bathrooms/hansgrohe/hero.jpg"
import Img from "../../images/bathrooms/hansgrohe/1.jpg"
import Img2 from "../../images/bathrooms/hansgrohe/2.jpg"
import Img3 from "../../images/bathrooms/hansgrohe/3.jpg"
import Img4 from "../../images/bathrooms/hansgrohe/4.jpg"
import Img5 from "../../images/bathrooms/hansgrohe/5.jpg"
import Img6 from "../../images/bathrooms/hansgrohe/6.jpg"
import Img7 from "../../images/bathrooms/hansgrohe/7.jpg"
import Img8 from "../../images/bathrooms/hansgrohe/8.jpg"
import Img9 from "../../images/bathrooms/hansgrohe/9.jpg"
import Img10 from "../../images/bathrooms/hansgrohe/10.jpg"
import Img11 from "../../images/bathrooms/hansgrohe/11.jpg"
import Img12 from "../../images/bathrooms/hansgrohe/12.jpg"
import Img13 from "../../images/bathrooms/hansgrohe/13.jpg"
import Img14 from "../../images/bathrooms/hansgrohe/14.jpg"
import Img15 from "../../images/bathrooms/hansgrohe/15.jpg"
import Img16 from "../../images/bathrooms/hansgrohe/16.jpg"
import Img17 from "../../images/bathrooms/hansgrohe/17.jpg"
import Img18 from "../../images/bathrooms/hansgrohe/18.jpg"
import Img19 from "../../images/bathrooms/hansgrohe/19.jpg"
import Img20 from "../../images/bathrooms/hansgrohe/20.jpg"
import Img21 from "../../images/bathrooms/hansgrohe/21.jpg"

const Hansgrohe = () => (
  <Layout>
    <SEO title="hansgrohe" />

    <Hero Short ImgPath={HeroImg} TopTitle="" MainTitle="hansgrohe" SubTitle=""></Hero>

    <div className="container">
    <FullCenter Title="Designed for Life- Perfection in Form and Function" Description="hansgrohe is established as one of the leading sanitary ware companies in the industry. Creativity and pure product innovation have been the keep the ever-growing success of the hansgrohe brand.<br></br>hansgrohe have always proud themselves as always being one step ahead of the competition. With hansgrohe, you can not only rely on a beautiful product to compliment your bathroom, but rest assured knowing that you have purchased a reliable, sturdy product designed for a long service life.<br></br>After all, quality starts from within."/>

      <div className="flex">
        <Gallery ImgPath={Img}></Gallery>
        <Gallery ImgPath={Img2}></Gallery>
        <Gallery ImgPath={Img3}></Gallery>
        <Gallery ImgPath={Img4}></Gallery>
        <Gallery ImgPath={Img5}></Gallery>
        <Gallery ImgPath={Img6}></Gallery>
        <Gallery ImgPath={Img7}></Gallery>
        <Gallery ImgPath={Img8}></Gallery>
        <Gallery ImgPath={Img9}></Gallery>
        <Gallery ImgPath={Img10}></Gallery>
        <Gallery ImgPath={Img11}></Gallery>
        <Gallery ImgPath={Img12}></Gallery>
        <Gallery ImgPath={Img13}></Gallery>
        <Gallery ImgPath={Img14}></Gallery>
        <Gallery ImgPath={Img15}></Gallery>
        <Gallery ImgPath={Img16}></Gallery>
        <Gallery ImgPath={Img17}></Gallery>
        <Gallery ImgPath={Img18}></Gallery>
        <Gallery ImgPath={Img19}></Gallery>
        <Gallery ImgPath={Img20}></Gallery>
        <Gallery ImgPath={Img21}></Gallery>
      </div>
    </div>

  </Layout>
)

export default Hansgrohe


