import { Link } from "gatsby"
import React, {useState} from "react"
import styled from "styled-components"
import media from "styled-media-query"


const BurgerMenu = styled.div`
    position:absolute;
    top: 35px;
    right: 40px;
    height: 50px;
    width: 35px;
    cursor: pointer;
    z-index: 100;
    transition: opacity .25s ease;
    ${media.greaterThan("1201px")`
        display:none;
    `}
`
const StyledUl = styled.ul`
  list-style:none;
  text-transform:uppercase;
  font-size:14px;
  display:block;
  position:relative;
  float:left;
  margin:0px 0 0 0;
  padding:0;
  background-color:white; 
  width:100%; 
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, height .35s;
  ${media.greaterThan("1201px") `
    background-color:transparent;
    margin:25px 0 0 0;
    float:right;
    width:auto; 
    height: auto;
    opacity:1;
    visibility: visible;
  `}
`
const StyledLi = styled.li`
    display:block;
    margin:10px 0px;
    text-align:center;
    width:100%;
    height:30px;
    padding:15px 0px;
    ${media.greaterThan("1201px") `
        margin:0px 20px;
        display:inline-block;
        text-align:left;
        padding:0;
        width:auto;
        height:auto;
    `}
`
const StyledLink = styled(Link)`
    color:black;
    display:block;
    text-decoration:none;
    width:100%;
    height:30px;
    padding:20px 0px 10px;
    
    &:hover{
        background-color:#e2e1e2;
    }
    ${media.greaterThan("1201px") `
    width:auto;
    height:auto;
    padding:0px;
        &:hover{
            font-weight:700;
            text-decoration:underline;
            background-color:transparent;
        }
    `}
`
const Navigation = () => {
    const [ open, setOpen ] = useState(false);
    
    return <>

        <BurgerMenu className={(open) ? 'openNav' : 'closedNav'} onClick={() => {setOpen(!open) }} >
            <span className="top"></span>
            <span className="middle"></span>
            <span className="bottom"></span>
        </BurgerMenu>
        <StyledUl className={(open) ? 'navigationOpen' : 'navigationClosed'}>
            <StyledLi><StyledLink to="/">Home</StyledLink></StyledLi>
            <StyledLi><StyledLink to="/about-us/">About us</StyledLink></StyledLi>
            <StyledLi><StyledLink to="/kitchens/">Kitchens</StyledLink></StyledLi>
            <StyledLi><StyledLink to="/bathrooms/">Bathrooms</StyledLink></StyledLi>
            <StyledLi><StyledLink to="/contact-us/">Contact us</StyledLink></StyledLi>
            <StyledLi><StyledLink to="/testimonals/">Testimonals</StyledLink></StyledLi>
        </StyledUl>
    </>
}

export default Navigation