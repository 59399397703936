import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Gallery from "../../components/gallery/gallery"
import FullCenter from "../../components/text/fullCenterBathrooms"
import Hero from "../../components/hero/hero"

import HeroImg from "../../images/bathrooms/matki/hero.jpg"
import Img from "../../images/bathrooms/matki/1.jpg"
import Img2 from "../../images/bathrooms/matki/2.jpg"
import Img3 from "../../images/bathrooms/matki/3.jpg"
import Img4 from "../../images/bathrooms/matki/4.jpg"
import Img5 from "../../images/bathrooms/matki/5.jpg"
import Img6 from "../../images/bathrooms/matki/6.jpg"
import Img7 from "../../images/bathrooms/matki/7.jpg"
import Img8 from "../../images/bathrooms/matki/8.jpg"
import Img9 from "../../images/bathrooms/matki/9.jpg"
import Img10 from "../../images/bathrooms/matki/10.jpg"
import Img11 from "../../images/bathrooms/matki/11.jpg"
import Img12 from "../../images/bathrooms/matki/12.jpg"
import Img13 from "../../images/bathrooms/matki/13.jpg"
import Img14 from "../../images/bathrooms/matki/14.jpg"
import Img15 from "../../images/bathrooms/matki/15.jpg"
import Img16 from "../../images/bathrooms/matki/16.jpg"
import Img17 from "../../images/bathrooms/matki/17.jpg"
import Img18 from "../../images/bathrooms/matki/18.jpg"

const Matki = () => (
  <Layout>
    <SEO title="Matki" />

    <Hero Short ImgPath={HeroImg} TopTitle="" MainTitle="Matki" SubTitle=""></Hero>


    <div className="container">
    <FullCenter Title="Exquisite design and supreme perfection: Pure Matki." Description="Matki is a well-known brand within luxury bathroom design, carrying an excellent reputation for designing and manufacturing high-quality Shower Enclosures, Shower Trays and Brassware.<br></br>Characterised by their unique and understated style, superior performance and ease of maintenance, Matki products are designed with the function and finish of every component in mind. From the seals and door closures, to the smallest fixing inside the hinges and the stunning quality of the mirror-polished Stainless Steel and Aluminium framing.<br></br>Based solely from UK sites in Bristol, Cornwall and Hampshire and established in 1971, Matki have a deep understanding and a pragmatic approach to shower design. Continuing to be family-run with experienced teams, many of whom have been with the company since the early days, there is a pride for the heritage Matki have created in showering and an ambition to develop it further."/>

      <div className="flex">
        <Gallery ImgPath={Img}></Gallery>
        <Gallery ImgPath={Img2}></Gallery>
        <Gallery ImgPath={Img3}></Gallery>
        <Gallery ImgPath={Img4}></Gallery>
        <Gallery ImgPath={Img5}></Gallery>
        <Gallery ImgPath={Img6}></Gallery>
        <Gallery ImgPath={Img7}></Gallery>
        <Gallery ImgPath={Img8}></Gallery>
        <Gallery ImgPath={Img9}></Gallery>
        <Gallery ImgPath={Img10}></Gallery>
        <Gallery ImgPath={Img11}></Gallery>
        <Gallery ImgPath={Img12}></Gallery>
        <Gallery ImgPath={Img13}></Gallery>
        <Gallery ImgPath={Img14}></Gallery>
        <Gallery ImgPath={Img15}></Gallery>
        <Gallery ImgPath={Img16}></Gallery>
        <Gallery ImgPath={Img17}></Gallery>
        <Gallery ImgPath={Img18}></Gallery>
      </div>
    </div>

  </Layout>
)

export default Matki


