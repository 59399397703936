import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero/hero"
import FullCenter from "../components/text/fullCenterNoBtn"
import ProductsSelection from "../components/products/allProductsSelect"
import Cta from "../components/cta/whiteCta"
import Gallery from "../components/gallery/gallery"

import HeroImg from "../images/ted-todd-flooring/Hero.jpg"
import ProductImg from "../images/ted-todd-flooring/Product-section-hero.jpg"
import Img from "../images/ted-todd-flooring/floor1.jpg"
import Img2 from "../images/ted-todd-flooring/floor2.jpg"
import Img3 from "../images/ted-todd-flooring/floor3.jpg"
import Img4 from "../images/ted-todd-flooring/floor4.jpg"
import Img5 from "../images/ted-todd-flooring/floor5.jpg"
import Img6 from "../images/ted-todd-flooring/floor6.jpg"
import Img7 from "../images/ted-todd-flooring/floor7.jpg"
import Img8 from "../images/ted-todd-flooring/floor8.jpg"
import Img9 from "../images/ted-todd-flooring/floor9.jpg"
import Img10 from "../images/ted-todd-flooring/floor10.jpg"
import Img11 from "../images/ted-todd-flooring/floor11.jpg"
import Img12 from "../images/ted-todd-flooring/floor12.jpg"


import productFlooring from "../images/homepage/products/placeholder-flooring.jpg"
import productBedrooms from "../images/homepage/products/placeholder-bedroom.jpg"
import productPaint from "../images/homepage/products/placeholder-paint.jpg"
import productKitchen from "../images/global/product-samples/kitchenSelect.jpg"
import productBathroom from "../images/homepage/products/placeholder-bathroom.jpg"

const Titleh5 = styled.h5`
  text-transform: uppercase;
  letter-spacing: 2px;
  margin:15px 0px;
`
const Titleh1 = styled.h1`
  letter-spacing: 2px;
  margin-bottom:20px;
  margin-top:20px;
`
const ProductsSection = styled.div`
  background-color:#d6d6d6;
  width:100%;
  height:auto;
  color:black;
  padding:40px 0px;
  overflow:hidden;
`
const ColOne = styled.div`
  width:100%;
  display:inline-block;
  margin:40px 0px;
  p{
    display:block;
    overflow:hidden;
    float:left;
  }
  ${media.greaterThan("medium") `
    width:45%;
  `}
`
const ColTwo = styled.div`
  display:none;
  background-image: url(${ProductImg});
  background-size:cover;
  background-repeat:no-repeat;
  height:250px;
  float:right;
  margin:40px 0px;
  ${media.greaterThan("medium") `
    width:45%;
    display:inline-block;
  `}
`

const TedTodd = () => (
  <Layout>
    <SEO title="Ted Todd Flooring" />

    <Hero Short ImgPath={HeroImg} TopTitle="Handcrafted flooring" MainTitle="Ted Todd Flooring" SubTitle="The most beautiful and innovative wood floors across the globe."></Hero>


    <div className="container">
      <FullCenter Title="Expertly Handcrafted Fine Wood Floors" 
        Description="Ted Todd’s ethos is simple. Create the most beautiful and innovative wood floors across the globe. Ted Todd manufacture flooring for people who love and appreciate unique, clever interior design.
        <br></br>At Ted Todd, the pioneering development team are constantly pushing the boundaries of what a wood floor can be. The endless research, innovation and patience has now rendered a product which is that is simply breathtaking.
        <br></br>Ted Todd flooring is designed for people who want their homes to tell a story, beyond the usual ‘out-of-the-box’ fixtures and fittings. Unrivalled in quality, originality and creative flair, you can almost taste the rich history of the slow-grown European Oak, to the rich tones of the traditional American Black Walnut. One thing is certain, every floor by Ted Todd has its own unique personality.
        <br></br>We challenge you not to fall in love with each and every one." />

        <div className="flex">
        <Gallery ImgPath={Img}></Gallery>
        <Gallery ImgPath={Img2}></Gallery>
        <Gallery ImgPath={Img3}></Gallery>
        <Gallery ImgPath={Img4}></Gallery>
        <Gallery ImgPath={Img5}></Gallery>
        <Gallery ImgPath={Img6}></Gallery>
        <Gallery ImgPath={Img7}></Gallery>
        <Gallery ImgPath={Img8}></Gallery>
        <Gallery ImgPath={Img9}></Gallery>
        <Gallery ImgPath={Img10}></Gallery>
        <Gallery ImgPath={Img11}></Gallery>
        <Gallery ImgPath={Img12}></Gallery>

        </div>

    </div>

    <ProductsSection>
      <div className="container">

        <ColOne>
          <Titleh5 white>Want to see more</Titleh5>
          <Titleh1>Explore with us</Titleh1>
          <hr className="small"></hr>
        </ColOne>

        <ColTwo></ColTwo>  
      </div>

      <div className="container flex">

        <ProductsSelection productsLarger ProductLink="/kitchens/" ImgPath={productKitchen} ProductTitle="Kitchens" DescriptionProduct="A kitchen is much more than just a place to cook. It has fast become the heart of the home and the hub of family life, it will witness many family memories."></ProductsSelection>
        <ProductsSelection productsLarger ProductLink="/bathrooms/" ImgPath={productBathroom} ProductTitle="Bathrooms" DescriptionProduct="From contemporary to traditional bathrooms. Creating the perfect bathroom is our priority through a vast range of choice"></ProductsSelection>
        <ProductsSelection ProductLink="/ted-todd-flooring/" ImgPath={productFlooring} ProductTitle="Ted Todd Flooring" DescriptionProduct="At Ted Todd, the pioneering development team are constantly pushing the boundaries of what a wood floor can be. The endless research, innovation and patience has now rendered a product which is that is simply breathtaking."></ProductsSelection>
        <ProductsSelection ProductLink="/laura-ashley-bedrooms/" ImgPath={productBedrooms} ProductTitle="Laura Ashley Bedrooms" DescriptionProduct="Laura Ashley Fitted Bedrooms include two expertly developed design options, Sherborne and Pelham. Both are available in eleven painted finishes, which can be accessorised with a wide range of handles."></ProductsSelection>
        <ProductsSelection ProductLink="/little-greene-paint/" ImgPath={productPaint} ProductTitle="Little Greene Paint" DescriptionProduct="With a vast variety of unique, traditional and contemporary colours and papers, you are sure to find your dream combinations here with our Industry Leading British Paint Manufacturer, Little Greene."></ProductsSelection>

      </div>
    </ProductsSection>
    <Cta></Cta>
  </Layout>
)

export default TedTodd


