import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

import Quote from "../../images/global/quoteOpen.png"


const QuoteBox = styled.div`
    width:100%;
    height:auto;
    display:block;
    background-color:white;
    background-image: url(${props => props.Img});
    background-size:cover;
    background-repeat:no-repeat;
    margin:20px 0px;
    position:relative;
    padding:0px 50px 40px 50px;
    box-shadow: 3px 3px 3px 3px #bfbfbf;
    ${media.greaterThan("medium") `
      width:34%;
      float:left;
      margin:10px 0;
    `}

    ${media.greaterThan("large") `
      width:35%;
    `}

    ${media.greaterThan("huge") `
      width:24%;
    `}
`

const Quoteable = styled.div`
  background-color:#d5ac63;
  position:absolute;
  width:60px;
  height:60px;
  left:40px;
  background-image: url(${Quote});
  background-size:50%;
  background-repeat:no-repeat;
  background-position:center;
`

const QuoteText = styled.p`
  text-align:center;
  margin-top:80px;

`

const QuoteAuthor = styled.p`
  font-weight:800;
  text-align:center;
`

const Reviews = ({QuoteDescription, Author}) => (
     <QuoteBox>
        <Quoteable></Quoteable>
        <QuoteText dangerouslySetInnerHTML={{ __html: QuoteDescription }} ></QuoteText>
        <QuoteAuthor dangerouslySetInnerHTML={{ __html: Author }} ></QuoteAuthor>
     </QuoteBox>
)

export default Reviews