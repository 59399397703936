import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Gallery from "../../components/gallery/gallery"
import FullCenter from "../../components/text/fullCenterBathrooms"
import Hero from "../../components/hero/hero"

import HeroImg from "../../images/bathrooms/perrin/hero.jpg"
import Img from "../../images/bathrooms/perrin/1.jpg"
import Img2 from "../../images/bathrooms/perrin/2.jpg"
import Img3 from "../../images/bathrooms/perrin/3.jpg"
import Img4 from "../../images/bathrooms/perrin/4.jpg"
import Img5 from "../../images/bathrooms/perrin/5.jpg"
import Img6 from "../../images/bathrooms/perrin/6.jpg"
import Img7 from "../../images/bathrooms/perrin/7.jpg"
import Img8 from "../../images/bathrooms/perrin/8.jpg"
import Img9 from "../../images/bathrooms/perrin/9.jpg"
import Img10 from "../../images/bathrooms/perrin/10.jpg"
import Img11 from "../../images/bathrooms/perrin/11.jpg"

const Perrin = () => (
  <Layout>
    <SEO title="Perrin and Rowe" />

    <Hero Short ImgPath={HeroImg} TopTitle="" MainTitle="Perrin and Rowe" SubTitle=""></Hero>

    <div className="container">
      <FullCenter Title="The fusion of function and beauty; durability and desirability" Description="The strength of the Perrin &amp; Rowe brand is built upon a clear understanding of the requirements in the luxury sector: product reliability, durability, quality and real value for money.<br><br> Renowned for our unrivalled quality and exacting attention to detail, our wide and varied product portfolio includes kitchen taps, bath and basin brassware, bathroom accessories and bathroom chinaware, all designed and manufactured in our own UK factories.<br><br> Perrin &amp; Rowe products are outstanding examples of deign excellence combined with cutting edge precision engineering. Our products are not mass produced; instead we concentrate on perfecting each individual product by controlling each process to our own exacting standards.<br><br> Our reward – Perrin &amp; Rowe products have consistently been specified and installed in the most stunning and luxurious hotels, resorts, spas and private residences around the world"/>

      <div className="flex">
        <Gallery ImgPath={Img}></Gallery>
        <Gallery ImgPath={Img2}></Gallery>
        <Gallery ImgPath={Img3}></Gallery>
        <Gallery ImgPath={Img4}></Gallery>
        <Gallery ImgPath={Img5}></Gallery>
        <Gallery ImgPath={Img6}></Gallery>
        <Gallery ImgPath={Img7}></Gallery>
        <Gallery ImgPath={Img8}></Gallery>
        <Gallery ImgPath={Img9}></Gallery>
        <Gallery ImgPath={Img10}></Gallery>
        <Gallery ImgPath={Img11}></Gallery>
        </div>
    </div>

  </Layout>
)

export default Perrin
