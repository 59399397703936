import styled from "styled-components"
import React from "react"
import media from "styled-media-query"

import Logo from "../../components/header/logo"
import Nav from "../../components/header/nav"

const StyledHeader = styled.header`
  background-color:white;
  border-bottom:2px solid #e6e6e6;
  width:100%;
  height:70px;
  padding:10px 10px;
  z-index:10;
  position:relative;
  top:0;
  max-width:1800px;
  margin:0 auto;
  ${media.greaterThan("1201px") `
    position:fixed;
  `}
  ${media.greaterThan("1820px")`
    position:relative;
    max-width:none;
  `}
`

const ContainerNav = styled.div`
  ${media.greaterThan("1201px") `
    max-width:1800px;
    margin:0 auto;
    padding:0px 20px;
    overflow:hidden;    
  `}
`

const Header = () => (
  <StyledHeader>
      <ContainerNav>
        <Logo></Logo>
        <Nav></Nav>
      </ContainerNav>
  </StyledHeader>
)

export default Header