import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Gallery from "../../components/gallery/gallery"
import FullCenter from "../../components/text/fullCenterBathrooms"
import Hero from "../../components/hero/hero"

import HeroImg from "../../images/bathrooms/victoria/hero.jpg"
import Img from "../../images/bathrooms/victoria/1.jpg"
import Img2 from "../../images/bathrooms/victoria/2.jpg"
import Img3 from "../../images/bathrooms/victoria/3.jpg"
import Img4 from "../../images/bathrooms/victoria/4.jpg"
import Img5 from "../../images/bathrooms/victoria/5.jpg"
import Img6 from "../../images/bathrooms/victoria/6.jpg"
import Img7 from "../../images/bathrooms/victoria/7.jpg"
import Img8 from "../../images/bathrooms/victoria/8.jpg"
import Img9 from "../../images/bathrooms/victoria/9.jpg"
import Img10 from "../../images/bathrooms/victoria/10.jpg"

const Victoria = () => (
  <Layout>
    <SEO title="Victoria + Albert" />

    <Hero Short ImgPath={HeroImg} TopTitle="" MainTitle="V I C T O R I A   +   A L B E R T" SubTitle=""></Hero>

    <div className="container">
      <FullCenter Title="V I C T O R I A   +   A L B E R T" Description="As the ultimate statement in quality and style, our model line-up is packed with inspirational free standing designs made from 100% QUARRYCAST<sup>&trade;</sup>, a blend of Volcanic Limestone<sup>&trade;</sup> and high performance resins. Naturally white, each item is individually hand-finished by craftsmen.<br><br> There are two main collections in the Victoria + Albert Bath range, Traditional and Modern, both offering numerous design solutions for your bathroom project, be it large or small.<br><br> With the addition of a range of taps and accessories you can create a totally coordinated bathroom.<br><br> All QUARRYCAST<sup>&trade;</sup> products have been tested by IAPMO to UPC and cUPC standards and every classic white model comes with a 25 year consumer guarantee.<br><br> Choose a QUARRYCAST<sup>&trade;</sup> bath or basin from Victoria + Albert and relax – you’ve made an investment that will last a lifetime."/>

      <div className="flex">
        <Gallery ImgPath={Img}></Gallery>
        <Gallery ImgPath={Img2}></Gallery>
        <Gallery ImgPath={Img3}></Gallery>
        <Gallery ImgPath={Img4}></Gallery>
        <Gallery ImgPath={Img5}></Gallery>
        <Gallery ImgPath={Img6}></Gallery>
        <Gallery ImgPath={Img7}></Gallery>
        <Gallery ImgPath={Img8}></Gallery>
        <Gallery ImgPath={Img9}></Gallery>
        <Gallery ImgPath={Img10}></Gallery>
        </div>
    </div>

  </Layout>
)

export default Victoria


